.campaign_header{
  z-index: 3;
  display: flex;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 1px 0 6px 0 rgba(0,0,0,0.1);
  height: 60px;
  min-height: 60px;
  padding: 0 35px 0 40px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(132, 148, 185,0.3);
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(132, 148, 185,0.3);
  }

  .campaign_tabs {
    display: flex;
    align-items: center;
    height: 60px;
    .tabs_wrapper > div .tabs_buttons_container button {
      opacity: 1;
    }
  }

  .campaign-status {
    margin: 0 20px 0 6px;
    padding: 0 20px;
    font-size: 10px;
    font-family: "InterMedium", sans-serif;
    letter-spacing: 0;
    text-transform: uppercase;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.archived {
      background: #CECECE;
      color: #FFFFFF;
    }
    &.finalized, &.finalizing {
      background-color: #EEF3FF;
      color: #324EA3;
    }
    &.inactive {
      background-color: #FEF3E8;
      color: #E27C12;
    }
    &.active {
      background-color: rgba(35, 155, 95, 0.1);
      color: #239B5F;
    }
    &.stopped_immediately {
      background-color: rgba(224, 32, 32, 0.1);
      color: #E02020;
    }
  }

  .users_list {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #E9E9E9;
    padding-left: 24px;
    margin-left: 24px;
    height: 100%;

    .user {
      &:nth-child(1) {
        z-index: 4;
      }
      &:nth-child(2) {
        z-index: 3;
      }
      &:nth-child(3) {
        z-index: 2;
      }
      margin-left: -6px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      height: 34px;
      width: 34px;
      border: 2px solid #FFFFFF;
      border-radius: 17px;
      background-color: #EEF3FF;
      font-size: 12px;
      font-family: "InterSemiBold", sans-serif;
      color: #383838;
      &.editor {
        background-color: #6429B3;
        color: #FFFFFF;
      }
      &.more_users {
        background-color: #F23EA6;
        color: #FFFFFF;
      }
    }
  }


  .campaign_controls_wrapper {
    height: 100%;
    display: flex;
    align-items: center;

    .edit_btn {
      font-size: 12px;
      line-height: 12px;
    }

    .default_button_wrapper {
      img {
        margin: 0 4px 0 0!important;
      }
    }

    .save_btn {
      margin-left: 8px;
      min-width: 92px;
      letter-spacing: 0;
    }

    .done_btn {
      margin-left: 8px;
      height: 34px;
      min-width: 92px;
      background-color: #61B71D;
      color: #ffffff;
      border-radius: 17.5px;
      font-size: 12px;
    }
  }
}
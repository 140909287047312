.view-product {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 64px;
  background: rgba(242, 242, 242, 0.6);
  &__wrapper {
    width: 100%;
    min-width: 600px;
    height: max-content;
    background-color: #FFFFFF;
    padding: 40px 40px 50px;
    display: flex;
    flex-flow: column;
    border-radius: 8px;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
  }
  &__back {
    color: #F23EA6;
    font-family: "InterBold",sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    width: max-content;
    &--icon {
      margin-right: 10px;
    }
  }
  &__title {
    font-family: "InterSemiBold", sans-serif;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 40px;
  }
  &__label {
    font-family: "InterSemiBold", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 8px;
  }
  &__images {
    margin-bottom: 30px;
  }
  &__values {
    display: flex;
    align-items: center;
    column-gap: 40px;
  }
  &__value {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 15px;
    margin-bottom: 30px;
    &.is-url {
      color: #F23EA6;
      font-family: "InterMedium", sans-serif;
    }
    &.is-image {
      margin-top: 13px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      & > p {
        margin: 0 15px;
      }
      & > img {
        border: 1px solid #E9E9E9;
        border-radius: 4px;
      }
    }
  }
  &__color-preview {
    height: 15px;
    width: 15px;
    border-radius: 2px;
    margin-right: 8px;
  }
  .default_button_wrapper {
    margin-top: 20px;
    .add_product {
      height: 40px!important;
      width: 153px!important;
      min-width: 153px!important;
    }
  }
}
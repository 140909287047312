.invite_user_wrapper{
  display: flex;
  justify-content: center;
  padding-top: 5%;
  width: 100%;
  background: rgba(242, 242, 242, 0.6);
  .back{
    color: #F23EA6;
    font-family: "InterBold",sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 50px;
    img {
      margin-right: 10px;
    }
  }
  >div{
    height: max-content;
    min-width: 520px;
    background-color: #FFFFFF;
    padding: 40px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border-radius: 8px;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
    .yellow_box {
      width: calc(100% - 80px);
      margin: 80px 0 40px;
      span {
        font-family: "InterSemiBold", sans-serif;
      }
    }
  }
  .title{
    font-family: "InterSemiBold",sans-serif;
    font-size: 24px;
    line-height: 29px;
    color: #383838;
  }
  .info{
    font-family: "InterSemiBold", sans-serif;
    color: #383838;
    font-size: 14px;
    line-height: 17px;
    margin: 30px 0 7px;
  }
  .input_wrapper{
    width: 100%;
    input{
      box-sizing: border-box;
      height: 37px;
      width: 100%;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding: 0 15px;
      font-family:"InterRegular",sans-serif;
      color: #111111;
      transition: all 0.3s linear;
      &::placeholder{
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus{
        border-color:#111111 ;
      }
    }
    p{
      padding-top: 6px;
      padding-bottom: 40px;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .select_wrapper{
    width: 100%;
  }
  .btn_wrapper {
    margin-top: 50px;
    display: flex;
    .default_button_wrapper {
      display: flex;
      .default_button.auth{
        margin-right: 20px;
        height: 41px;
        width: 209px;
        min-width: 153px;
        font-size: 14px;
      }
    }
    &.go_to_users {
      width: 100%;
      justify-content: center;
    }
  }
  .invitation_link_wrapper {
    width: 100%;
    .inv_title {
      font-family: "InterSemiBold", sans-serif;
      font-size: 16px;
      line-height: 32px;
      color: #383838;
      margin: 50px 0 14px;
    }
    .inv_link_block {
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 40px);
      background: #F2F2F2;
      border-radius: 4px;
      & > div {
        display: flex;
        align-items: center;
        & > span {
          font-size: 14px;
          line-height: 17px;
          color: #AAB3C1;
          margin-right: 18px;
        }
        & > p {
          font-size: 12px;
          line-height: 15px;
          color: #656565;
        }
      }
    }
  }
}

.email-and-role {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 160px;
  column-gap: 30px;
  width: 100%;
  .input_wrapper, .select_wrapper {
    margin-top: 7px;
  }
}

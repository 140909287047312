.add-magento-container{
  display: flex;
  justify-content: center;
  padding: 64px;
  width: 100%;
  box-sizing: border-box;
  background: rgba(242, 242, 242, 0.6);
  .back {
    color: #F23EA6;
    font-family: "InterBold",sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 50px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
  & > section {
    height: max-content;
    width: 100%;
    box-sizing: border-box;
    background-color: #FFFFFF;
    padding: 40px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border-radius: 8px;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
  }
  .title {
    font-family: "InterSemiBold",sans-serif;
    font-size: 24px;
    line-height: 29px;
    color: #383838;
  }
  .btn_wrapper {
    display: flex;
    .default_button_wrapper {
      display: flex;
      .default_button.auth{
        margin-right: 20px;
        height: 41px;
        width: 209px;
        min-width: 153px;
        font-size: 14px;
      }
    }
  }
}

.add-magento {
  &__info {
    margin: 30px 0 50px;
    p {
      color: #383838;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &__form-wrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    &--label {
      font-size: 14px;
      font-family: "InterSemiBold", sans-serif;
      margin-bottom: 10px;
    }
    &--description {
      margin: 40px 0;
      font-size: 16px;
      line-height: 24px;
    }
    &--fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;
      .error_text {
        min-height: 20px;
      }
    }
  }
  &__configure-api {
    width: 100%;
    box-sizing: border-box;
    &--description {
      margin: 30px 0;
      font-size: 16px;
      line-height: 24px;
      strong {
        font-family: "InterSemiBold", sans-serif;
        font-weight: normal;
      }
    }
    &--label {
      font-size: 14px;
      font-family: "InterSemiBold", sans-serif;
      margin-bottom: 10px;
    }
    form {
      width: 50%;
    }
  }
}
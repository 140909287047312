.campaign_canvas{
  min-height: calc(100vh - 270px);
  padding-bottom: 150px;
  width: 100%;
  background-color: #F8F8F8;
  background-image: radial-gradient(#d8d8d8 1px, transparent 0);
  background-size: 20px 20px;
  transition: all 0.3s linear;
  .empty_canvas_placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 19px;
    color: #9B9B9B;
    height: 142px;
    width: 70%;
    margin: 80px auto;
    background-color: #FFFFFF!important;
    border: 2px dashed #CECECE;
    box-sizing: border-box;
    border-radius: 15px;
  }
  & > div {
    background: transparent!important;
    & > div {
      position: relative;
      &:after {
        position: absolute;
        background: url("../../../assets/image/arrow_for_droplist.svg") no-repeat;
        content: '';
        bottom: -51px;
        left: calc(50% - 5px);
        height: 52px;
        width: 11px;
      }
      &:last-of-type {
        &:before {
          position: absolute;
          background: url("../../../assets/image/new_item_droplist.svg") no-repeat;
          content: '';
          bottom: -116px;
          left: 0;
          height: 68px;
          width: 640px;
        }
      }
    }
  }
}
.is_dragging{
  &:before{
    display: none;
  }
  &:after{
    display: none;
  }
}

.pagination-container{
  display: flex;
  justify-content: center;
  margin: 40px 0 20px;
  align-items: center;
  background-color: transparent;
}

.pagination-list {
  background: #FFFFFF;
  border: 0.85px solid #CECECE;
  border-radius: 3.4px;
  display: flex;
  li {
    min-width: 32px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    border-right: 1px solid #CECECE;
    &:last-of-type {
      border-right: none;
    }
    &:hover{
      background: rgba(132, 148, 185, 0.1);
    }
    a{
      font-family: "MontSemiBold",sans-serif;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0 5px;
      box-sizing: border-box;
    }
  }
}


.pagination-link {
  cursor: pointer;
  background: transparent;
  color: #F23EA6;
  text-decoration: none;
  text-align: center;
  line-height: 17px;
  height: 36px;
  min-width: 32px;
  font-size: 14px;
  &.pagination-link-active {
    background: #F23EA6;
    color: #ffffff;
    &:hover{
      opacity: 0.7;
    }
  }
}

.pagination-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  a {
    cursor: pointer;
    background: transparent;
    height: 36px;
    width: 32px;
    color: #F23EA6;
    text-decoration: none;
    text-align: center;
    line-height: 17px;
  }
}
.next, .previous {
  margin: 0;
  min-width: 52px!important;

  &.disabled {
    &:hover{
      background: transparent;
    }
    a {
      opacity: 0.5;
      cursor: not-allowed;

    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F23EA6;
    font-size: 14px;
    line-height: 17px;
  }
}


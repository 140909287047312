.tags {
  border: 1px solid #CECECE!important;
  border-radius: 4px!important;
  min-height: 78px;
  position: relative;
  padding: 6px 0 0 6px;
  /* clicking anywhere will focus the input */
  cursor: text;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 16px;

  &__wrapper {
    width: 100%;
    &.not-active {
      pointer-events: none;
    }
  }
  &__info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    & > p {
      color: #9B9B9B;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &.is-focused {
    border-color: #B1B1B1;
  }

  &__selected {
    display: inline;
  }

  &__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border-radius: 12.5px;
    background-color: #F23EA6;
    color: #FFFFFF;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
    font-family: "InterRegular", sans-serif;
  }

  &__selected-tag:after {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #F5F5F5;
    content: "\00d7";
    font-family: "InterSemiBold", sans-serif;
    color: #F23EA6;
    margin-left: 8px;
    display: inline-block;
  }

  &__selected-tag:hover,
  &__selected-tag:focus {
    border-color: #B1B1B1;
  }

  &__search {
    display: inline-block;

    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;
  }

  &__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
    font-family: "InterRegular", sans-serif!important;
    background-color: transparent;
  }

  &__search-input::-ms-clear {
    display: none;
  }

  &__suggestions {
    position: absolute;
    z-index: 1000;
  }

  &__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 6px 0 rgba(43,44,51,0.3);
    min-width: 144px;
  }

  &__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 10px 14px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }

  &__suggestions li mark {
    background: none;
  }

  &__suggestions li:hover {
    cursor: pointer;
    background-color: #F4F4F4;
  }
}
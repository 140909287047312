.public-api-access {
  &__page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background: rgba(242, 242, 242, 0.6);
  }
  &__wrapper {
    box-sizing: border-box;
    background-color: #FFFFFF;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border-radius: 8px;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
    width: 100%;
    margin: 64px;
    padding: 40px 40px 50px;
  }
  &__back {
    margin-right: auto;
    width: max-content;
    color: #F23EA6;
    font-size: 14px;
    font-family: "InterBold", sans-serif;
    letter-spacing: 1px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    & > img {
      margin-right: 10px;
    }
  }
  &__title {
    width: 100%;
    font-size: 24px;
    font-family: "InterSemiBold",sans-serif;
    line-height: 26px;
    margin: 50px 0 40px;
  }
  &__description {
    margin-bottom: 50px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  &__access-token {
    margin-bottom: 30px;
    border-radius: 8px;
    background-color: #F2F2F2;
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-family: "InterSemiBold", sans-serif;
    }
    img {
      cursor: pointer;
    }
  }
  &__btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
  }
  &__separator {
    margin: 50px 0;
    height: 1px;
    width: 100%;
    background-color: #E2E5EB;
  }
  &__generate-btn {
    min-width: 209px!important;
  }
  &__documentation-btn {
    min-width: 256px!important;
  }
  &__copy-btn, &__regenerate-btn {
    min-width: 153px!important;
  }
}

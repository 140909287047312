.default_dialog_root {
  .paper_custom_dialog{
    border-radius: 6px;
    min-width: unset;
    width: unset;
    max-width: unset;
    box-shadow: none;
  }
  .dialog_close_button {
    cursor: pointer;
    position: absolute;
    right: 23px;
    top: 23px;
    background: transparent;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover{
      background: transparent;
      transform: rotate(-180deg);
    }
  }
}

.dialog_wrapper {
  padding: 70px 70px 60px;
  display: flex;
  position: relative;
  background-color: #FFFFFF;
  max-height: 850px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;

    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: grey;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: grey;
  }
  &.pickup_address {
    padding: 40px 40px 0;
  }
  &.cropper-dialog {
    padding: 0;
    display: flex;
    flex-flow: column;
    min-width: 400px;
    .item_radio {
      margin: 24px auto 0;
      width: max-content;
      .option_wrapper {
        & > div{
          & > input{
            display: none;
            & + label{
              position: relative;
              cursor: pointer;
              font-family: "InterSemiBold",sans-serif;
              font-size: 16px;
              line-height: 24px;
              padding-left: 36px;
              &:before, &:after{
                content: "";
                position: absolute;
                border-radius: 50%;;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
              }
              &:before{
                top: 2px;
                left: 0;
                height: 20px;
                width: 20px;
                background: url("../../../assets/image/RadioOff.png") no-repeat;
              }
            }
            &:checked + label:before{
              background: url("../../../assets/image/RadioOn.png") no-repeat;
            }
          }
        }
      }
    }
    .btn_wrapper {
      margin: 24px auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .default_button_wrapper {
        &:first-of-type {
          margin-right: 20px;
        }
        button {
          min-width: 153px!important;
        }
      }
    }
  }
}

.image_root_dialog {
  background-color: transparent!important;
  .image_dialog {
    padding: 26px 70px;
    max-height: 700px;
    background-color: transparent;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      min-height: 50px;
      img {
        max-height: 650px;
        max-width: 1000px;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
      height:5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;

      background: #EDF2F8;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: grey;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: grey;
    }
  }
}
.campaigns_title_block{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .block{
    display: flex;
    flex-flow: column;
    .title{
      color: #383838;
      margin-bottom: 38px;
      font-size: 24px;
      line-height: 29px;
      font-family: "InterSemiBold", sans-serif;
    }
    .title_panel{
      display: flex;
      align-items: center;
      .search{
        position: relative;
        margin-right: 20px;
        input{
          box-sizing: border-box;
          height: 37px;
          width: 288px;
          border: 1px solid #CECECE;
          border-radius: 4px;
          background-color: #FFFFFF;
          padding: 0 20px 0 39px;
          font-family:"InterRegular",sans-serif;
          color: #111111;
          transition: all 0.3s linear;
          &::placeholder{
            color: #9B9B9B;
            font-size: 12px;
          }
          &:focus{
            border-color:#111111 ;
          }
        }
        button{
          display: flex;
          align-items: center;
          background: transparent;
          justify-content: center;
          position: absolute;
          width: 16px;
          height: 16px;
          left: 15px;
          top: calc(50% - 8px);
          img{
            transition: all 0.3s linear;
          }
        }
      }
      .select_wrapper{
        width: 234px;
        margin-right: 20px;
      }
    }
  }
}

.campaigns_content {
  display: flex;
  flex-flow: column;
  min-width: 950px;
  & > div {
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
    display: flex;
    justify-content: space-between;
    .content {
      width: calc(100% - 80px);
      display: flex;
      align-items: center;
    }
    .img_block {
      margin-right: 30px;
      height: 111px;
      width: 133px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px 0 0 8px;
      img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        max-width: 133px;
      }
    }
    .user_info {
      display: flex;
      justify-content: center;
      flex-flow: column;
      margin-right: 115px;
      position: relative;
      width: 35%;
      height: 100%;
      .is_owner {
        background: rgba(100, 41, 179, 0.1);
        color: #6429B3;
        position: absolute;
        top: 0;
        border-radius: 0 0 2px 2px;
        font-family: "InterMedium", sans-serif;
        font-size: 10px;
        line-height: 16px;
        padding: 0 10px;
        text-transform: uppercase;
      }
      .campaign-status {
        text-transform: uppercase;
        position: absolute;
        top: 0;
        border-radius: 0 0 2px 2px;
        font-family: "InterMedium", sans-serif;
        font-size: 10px;
        line-height: 16px;
        padding: 0 10px;
        &.is_owner {
          left: 70px;
        }
        &.archived {
          background: #CECECE;
          color: #FFFFFF;
        }
        &.finalized, &.finalizing {
          background-color: #EEF3FF;
          color: #324EA3;
        }
        &.inactive {
          background-color: #FEF3E8;
          color: #E27C12;
        }
        &.active {
          background-color: rgba(35, 155, 95, 0.1);
          color: #239B5F;
        }
        &.stopped_immediately {
          background-color: rgba(224, 32, 32, 0.1);
          color: #E02020;
        }
      }
      .is_archived {
        background-color: #CECECE;
        color: #FFFFFF;
        left: 70px;
        position: absolute;
        top: -31px;
        border-radius: 0 0 2px 2px;
        font-family: "InterMedium", sans-serif;
        font-size: 10px;
        line-height: 16px;
        padding: 0 10px;
        text-transform: uppercase;
      }
      .name {
        color: #383838;
        font-family: "InterSemiBold",sans-serif;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
        max-width: 600px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      >div{
        width: 100%;
      }
      .last_updated_info{
        font-size: 14px;
        line-height: 17px;
        color: #9B9B9B;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span{
          margin: 0 3px;
          color: #656565;
        }
      }
    }
    .data_info{
      display: flex;
      flex-flow: column;
      margin-right: 50px;
      span{
        color: #9B9B9B;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 14px;
      }
      p{
        color: #383838;
        font-family: "InterSemiBold",sans-serif;
        font-size: 20px;
      }
    }
    .more_info_btn{
      display: flex;
      align-items: center;
      margin-right: 31px;
      .leave_btn {
        margin-right: 24px;
      }
      img{
        margin: 0 10px;
        cursor: pointer;
      }
    }
    .menu {
      z-index: 1000;
      right: 30px;
      top: 81px;
      padding: 10px 0;
      position: absolute;
      box-sizing: border-box;
      height: max-content;
      width: 219px;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
      color: #000000;
      & > button {
        width: 100%;
        padding: 10px 50px 10px 24px;
        text-align: left;
        font-size: 14px;
        font-family: "InterRegular", sans-serif;
        display: flex;
        align-items: center;
        &:hover {
          background-color: rgba(0,0,0,0.05);
        }
        img {
          width: 12px;
          margin-right: 12px;
          &.delete-icon {
            width: 10px;
            margin-right: 14px;
          }
        }
      }
      .campaign_balance {
        text-align: start;
        padding: 10px 50px 10px 24px;
        border-bottom: 1px solid #CECECE;
        margin-bottom: 10px;
        .balance_label {
          color: #9B9B9B;
          font-size: 14px;
          line-height: 17px;
        }
        .balance_value {
          color: #383838;
          font-family: "InterSemiBold", sans-serif;
          font-size: 20px;
          margin: 7px 0 10px;
          display: flex;
          align-items: center;
        }
        .switch_btn {
          margin: 10px 0!important;
        }
      }
    }
  }
}

.campaigns_wrapper {
  .pagination_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.campaign-editable-name {
  width: 100%;
  padding-right: 4px;
  z-index: 1;
  position: relative;

  &.is-editing {
    &:after {
      content: '';
      width: 100%;
      height: 30px;
      background-color: #FFFFFF;
      position: absolute;
      left: 0;
      bottom: -32px;
    }
  }

  .editable-text__text {
    font-family: "InterSemiBold", sans-serif;
    font-size: 20px;
    line-height: 38px;
    color: #000000;
  }

  .editable-text__input {
    height: 38px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    font-size: 14px;


    &::placeholder {
      font-size: 14px;
    }
  }

}
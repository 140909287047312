.multiple_wrapper{
  position: absolute;
  top: -17px;
  left: -17px;
  z-index: 1;
  .info_type{
    padding: 0 30px;
    .title{
      display: flex;
      align-content: center;
      padding-bottom: 10px;
      span {
        color: #383838;
        font-family: "InterSemiBold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        margin-right: 5px;
      }
      img {
        cursor: help;
      }
    }

    .item_radio{
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 30px;
      &:first-child{
        margin-bottom: 10px;
      }
      & > div{
        display: flex;
        align-items: center;
        margin-right: 40px;
        & > input{
          display: none;
          & + label{
            position: relative;
            cursor: pointer;
            padding-left: 30px;
            &:before, &:after{
              content: "";
              position: absolute;
              border-radius: 50%;;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              transition: all 0.3s ease;
            }
            &:before{
              top: 0;
              left: 0;
              height: 20px;
              width: 20px;
              background: url("../../../../../assets/image/RadioOff.png") no-repeat;
            }
          }
          &:checked + label:before{
            background: url("../../../../../assets/image/RadioOn.png") no-repeat;

          }
        }
      }
      label{
        color: #383838;
        font-family: "InterRegular",sans-serif;
        font-size: 12px;
        line-height: 20px;
      }
    }

  }

  .answers_block{
    padding: 0 30px;
    .title{
      display: flex;
      align-content: center;
      padding-bottom: 12px;
      span {
        color: #383838;
        font-family: "InterSemiBold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        margin-right: 5px;
      }
      img {
        cursor: help;
      }
    }
    .drag_info{
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      >span{
        margin-right: 10px;
        height: 100%;
        display: flex;
        align-items: center;
      }

      input{
        height: 38px;
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        border: 1px solid #CECECE;
        border-radius: 4px;
        background-color: #FFFFFF;
        color: #383838;
        font-family: "InterRegular",sans-serif;
        font-size: 12px;
        line-height: 15px;
      }
      >button{
        border-radius: 4px;
        background: transparent;
        cursor: pointer;
        margin-left: 20px;
      }

    }

  }
  .btn_wrapper{
    padding: 10px 0 0 20px;
    box-sizing: border-box;
  }
  .images_add{
    padding: 0 30px;
    box-sizing: border-box;
    flex-flow: column;
    margin: 30px 0;
    .title {
      display: flex;
      align-content: center;
      padding-bottom: 14px;
      & > img {
        cursor: help;
      }
      span {
        color: #383838;
        font-family: "InterSemiBold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        margin-right: 5px;
        cursor: auto;
      }
    }
    .upload_block{
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      height: 119px;
      width: 100%;
      border: 2px dashed #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      cursor: pointer;
      img{
        margin-bottom: 22px;
      }
      span{
        color: #9B9B9B;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .drag_img{
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      >span{
        margin-right: 10px;
        height: 100%;
        display: flex;
        align-items: center;
      }
      >p{
        display: flex;
        align-items: center;
        height: 25px;
        width: 25px;
        border-radius: 2px;
        margin-right: 15px;
        img{
          height: 25px;
          width: 25px;
          background-size: 100% 100%;
        }
      }
      >div{
        color: #383838;
        font-size: 12px;
        line-height: 15px;
        margin-right: 15px;
      }
      >button{
        border-radius: 4px;
        background: transparent;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}
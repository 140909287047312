.confirmation-dialog {
  width: 406px;
  text-align: center;
  height: max-content;
  &__title {
    color: #F23EA6;
    font-family: "InterSemiBold", sans-serif;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 24px;
  }
  &__description {
    color: #383838;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 48px;
  }
  &__campaigns-list {
    max-height: 30vh;
    min-height: 100px;
    overflow: auto;
    width: 100%;
    margin-bottom: 48px;
    &::-webkit-scrollbar {
      width: 5px;
      height:5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;

      background: #EDF2F8;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: grey;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: grey;
    }
  }
  &__campaign {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E2E5EB;
    padding: 16px 0;
    &-name {
      font-size: 20px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 22px;
      text-align: start;
    }
    &-icon {
      height: 64px;
      width: 64px;
      margin-right: 24px;
      border-radius: 3.5px;
      object-fit: cover;
    }
  }
  &__buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .default_button_wrapper {
      margin: 0 10px;
      .default_button {
        width: 153px!important;
        min-width: 153px!important;
      }
      .default_button_cancel {
        background-color: #F5F5F5!important;
      }
    }
  }
}
.tabs_wrapper {
  align-items: flex-end;
  height: 60px;
  & > div {
    height: 100%;
    & > span {
      background-color: #F23EA6;
    }
    .tabs_buttons_container {
      height: 100%;
      button {
        min-height: unset!important;
        min-width: 52px!important;
      }
    }
  }
  .MuiTab-wrapper {
    display: flex;
    align-items: center;
    flex-flow: row;
    img {
      width: 20px;
      margin-bottom: 0!important;
      margin-right: 8px;
    }
  }
}

.tab_wrapper {
  min-width: unset!important;
  span {
    font-size: 14px;
    font-family: "InterSemiBold", sans-serif;
    color: #9B9B9B;
    text-transform: none;
  }
  &.tab_selected {
    span {
      color: #F23EA6;
    }
  }
}

.tab_with_error {
  position: relative;
  & > span {
    color: #E02020!important;
  }
  &:before{
    position: absolute;
    content: "";
    top: calc(50% - 15px);
    left: 23px;
    height: 16px;
    width: 16px;
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Ewarning%3C/title%3E%3Cg id='Admin' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Campaigns---cost---validation' transform='translate(-402.000000, -72.000000)'%3E%3Cg id='actionbar' transform='translate(0.000000, 60.000000)'%3E%3Cg id='nav' transform='translate(390.000000, 12.000000)'%3E%3Cg id='warning' transform='translate(12.000000, 0.000000)'%3E%3Ccircle id='Oval' fill='%23FFFFFF' cx='8' cy='8' r='8'%3E%3C/circle%3E%3Cpath d='M8,0 C3.6,0 0,3.6 0,8 C0,12.4 3.6,16 8,16 C12.4,16 16,12.4 16,8 C16,3.6 12.4,0 8,0 Z M8,14 C4.7,14 2,11.3 2,8 C2,4.7 4.7,2 8,2 C11.3,2 14,4.7 14,8 C14,11.3 11.3,14 8,14 Z' id='Shape' fill='%23E02020' fill-rule='nonzero'%3E%3C/path%3E%3Crect id='Rectangle' fill='%23E02020' fill-rule='nonzero' x='7' y='4' width='2' height='5'%3E%3C/rect%3E%3Ccircle id='Oval' fill='%23E02020' fill-rule='nonzero' cx='8' cy='11' r='1'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
  }
}

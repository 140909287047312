.my_campaign_block{
  display: flex;
  flex-flow: column;
  margin-bottom: 35px;
  >span{
    color: #000000;
    font-family: "InterSemiBold",sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    line-height: 20px;
    margin-bottom: 22px;
    img{
      margin-right: 10px;
    }
  }
  >div{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    button{
      cursor: grab;
      display: flex;
      height: 86px;
      width: 86px;
      align-items: center;
      justify-content: space-between;
      flex-flow: column;
      position: relative;
      border: 2px solid #E9E9E9;
      border-radius: 4px;
      transition: all 0.3s ease;
      .unhovered-icon {
        display: flex;
      }
      .hover{
        display: none;
        z-index: 9;
        transition: all ease .3s;
        height: 52px;
      }
      >div{
        display: flex;
        height: 100%;
      }
      p{
        color: #9B9B9B;
        font-family: "InterSemiBold",sans-serif;
        font-size: 12px;
        padding-bottom: 15px;
      }
      .coming_soon {
        position: absolute;
        height: 86px;
        width: 86px;
        cursor: wait;
        left: -2px;
        top: -2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: "InterSemiBold",sans-serif;
        line-height: 12px;
        border-radius: 4px;
        span {
          max-width: 70px;
        }
      }
    }
  }
}
.challenges_block{
  >div{
    button{
      &:hover{
        opacity: 1;
        border: 2px solid #324EA3;
        p{
          color: #324EA3;
        }
        .unhovered-icon {
          display: none;
        }
        .hover{
          opacity: 1;
          z-index: 9;
          display: flex;
        }
      }
    }
  }
}

.responses_block{
  >div{
    button{
      &:hover{
        opacity: 1;
        border: 2px solid #069284;
        p {
          color: #069284;
        }
        .unhovered-icon {
          display: none;
        }
        .hover{
          opacity: 1;
          z-index: 9;
          display: flex;
        }
      }
    }
  }
}


.rewards_block{
  >div{
    button{
      &:hover{
        opacity: 1;
        border: 2px solid #FE8F1D;
        p{
          color: #FE8F1D;
        }
        .unhovered-icon {
          display: none;
        }
        .hover{
          opacity: 1;
          z-index: 9;
          display: flex;
        }
      }
    }
  }
}
.photo_wrapper{
  position: absolute;
  top: -17px;
  left: -17px;
  z-index: 1;
  .textarea_description_block_my_campaign{
    padding: 0 30px;
  }
  .images_add{
    padding: 0 30px;
    box-sizing: border-box;
    flex-flow: column;
    margin: 30px 0;
    .title {
      display: flex;
      align-content: center;
      padding-bottom: 14px;
      & > img {
        cursor: help;
      }
      span {
        color: #383838;
        font-family: "InterSemiBold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        margin-right: 5px;
        cursor: auto;
      }
    }
    .upload_block{
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      height: 119px;
      width: 100%;
      border: 2px dashed #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      cursor: pointer;
      img{
        margin-bottom: 22px;
      }
      span{
        color: #9B9B9B;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .drag_img{
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      >span{
        margin-right: 10px;
        height: 100%;
        display: flex;
        align-items: center;
      }
      >p{
        display: flex;
        align-items: center;
        height: 25px;
        width: 25px;
        border-radius: 2px;
        margin-right: 15px;
        img{
          height: 25px;
          width: 25px;
          background-size: 100% 100%;
        }
      }
      >div{
        color: #383838;
        font-size: 12px;
        line-height: 15px;
        margin-right: 15px;
      }
      >button{
        border-radius: 4px;
        background: transparent;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}
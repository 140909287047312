.campaign_wizard_container {
  width: 100%;
  display: flex;
  flex-flow: column;
  .campaign_wrapper{
    display: flex;
    width: 100%;
    min-height: calc(100vh - 120px);
    position: relative;
    height: 100%;
    &.height-100 {
      .campaign_canvas {
        height: 100%;
      }
    }
  }
}

.audience_panel, .distribution_panel, .analytics_panel, .cost_panel, .deliveries_panel {
  z-index: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 358px;
  min-width: 358px;
  background-color: #FFFFFF;
  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  padding-top: 30px;
  box-sizing: border-box;
  position: relative;
  .info_wrapper {
    color: #30837B;
    background: #DEFFEE;
    border-radius: 8px;
    padding: 30px;
    width: calc(100% - 100px);
    .title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      p {
        font-family: "InterBold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.15px;
      }
    }
    .separator_block {
      width: 40px;
      height: 4px;
      background: #00685E;
      margin: 14px 0 20px;
    }
    h4 {
      font-family: "InterBold", sans-serif;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.116667px;
      margin-bottom: 12px;
    }
    .info_text {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.116667px;
      &.big_margin_bottom {
        margin-bottom: 50px;
      }
      &.small_margin_bottom {
        margin-bottom: 36px;
      }
    }
  }
}

.step-results {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 358px;
  display: unset;
  box-sizing: border-box;
  transition: 0.2s linear all;
  background-color: #FFFFFF;
  box-shadow: -4px 0 16px 0 rgba(88,88,88,0.15);
  padding: 30px 30px 60px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;

    background: #EDF2F8;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: grey;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: grey;
  }
  &__is-hidden {
    display: none;
  }
  &__hide-icon {
    transform: rotate(180deg);
    height: 12px;
    width: 12px;
    margin: 2px 2px 0 0;
  }
  &__hide-results {
    display: flex;
    align-items: center;
    color: #F23EA6;
    font-family: "InterSemiBold", sans-serif;
    font-size: 10px;
    line-height: 16px;
  }
  &__group-title {
    margin: 50px 0 30px;
    color: #9B9B9B;
    font-family: "InterSemiBold", sans-serif;
    font-size: 11px;
    line-height: 12px;
    text-transform: uppercase;
  }
  &__no-items {
    color: #656565;
    font-size: 12px;
    line-height: 13px;
  }
  &__block {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 50px;
  }
  &__icon {
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }
  &__info {
    display: flex;
    flex-flow: column;
    width: calc(100% - 26px);
    &--header {
      display: flex;
      justify-content: space-between;
      &-rating {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 13px;
        &-score {
          color: #000000;
          font-family: "InterMedium", sans-serif;
        }
        &-icon {
          margin: 0 10px 1px 4px;
          width: 10px;
          height: 10px;
        }
        &-total {
          opacity: 0.5;
          color: #656565;
        }
      }
    }
    &--title {
      color: #000000;
      font-family: "InterSemiBold", sans-serif;
      font-size: 16px;
      line-height: 17px;
    }
    &--download {
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
      font-size: 10px;
      line-height: 16px;
      display: flex;
      align-items: center;
      &-icon {
        margin-right: 4px;
        width: 12px;
        height: 12px;
      }
    }
    &--total {
      display: flex;
      align-items: center;
      color: #656565;
      font-size: 12px;
      line-height: 13px;
      margin-top: 8px;
      &-number {
        font-family: "InterSemiBold", sans-serif;
        color: #000000;
      }
      &-lowercase {
        text-transform: lowercase;
      }
    }
    &--multiple {
      display: flex;
      flex-flow: column;
      width: 100%;
      margin-top: 4px;
      .most-popular {
        color: #000000;
        font-family: "InterBold", sans-serif;
      }
      &-row {
        margin-top: 12px;
        display: flex;
        align-items: center;
        width: 100%;
        color: #656565;
        font-size: 12px;
        line-height: 13px;
      }
      &-image {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        object-fit: cover;
        &-wrapper {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          position: relative;
          &:after {
            z-index: 10;
            position: absolute;
            background: url("../../assets/image/image-hover.svg") no-repeat;
            content: '';
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            display: none;
          }
          &:hover {
            &:after {
              display: unset;
            }
          }
        }
      }
      &-check {
        margin-right: 4px;
        width: 8px;
        height: 8px;
      }
      &-text {
        width: max-content;
        max-width: calc(90% - 32px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 15px;
        &.with-image {
          max-width: calc(90% - 64px);
        }
      }
      &-separator {
        flex: 1;
        background: transparent;
        height: 8px;
        border-bottom: 1px dashed #CECECE;
        margin: 0 4px;
      }
      &-count {
        font-family: "InterSemiBold", sans-serif;
      }
    }
    &--bars {
      margin-top: 4px;
      display: flex;
      flex-flow: column;
      width: 100%;
      &-row {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      &-label {
        color: #656565;
        font-size: 12px;
        line-height: 13px;
        display: flex;
        justify-content: space-between;
        width: 22px;
      }
      &-icon {
        margin: 0 0 1px 4px;
        width: 10px;
        height: 10px;
      }
      &-bar {
        height: 4px;
        width: 160px;
        border-radius: 3px;
        background-color: rgba(100,41,179,0.1);
        position: relative;
      }
      &-filled {
        position: absolute;
        left: 0;
        top: 0;
        height: 4px;
        border-radius: 3px;
        background-color: #6429B3;
      }
      &-value {
        color: #656565;
        font-family: "InterSemiBold", sans-serif;
        font-size: 12px;
        line-height: 13px;
        width: 22px;
        text-align: right;
      }
    }
  }
}
.campaign-report {
  &__wrapper {
    display: flex;
    flex-flow: column;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px -2px rgba(88,88,88,0.3), 0 3px 13px 2px rgba(89,89,89,0.05);
    padding: 32px 40px 40px;
  }
  &__meta-controls {
    margin: 40px 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .date-pickers {
      display: flex;
      align-items: center;
      column-gap: 24px;
    }
    .default_button_wrapper {
      .default_button {
        font-size: 12px;
        height: 35px;
        min-width: 151px;
        border: 1px solid #F23EA6;
        border-radius: 17.5px;
        padding: 0 20px;
        box-sizing: border-box;
      }
    }
  }
  &__table_container {
    width: 100%;
  }
  &__table_header {
    user-select: none;
    .row_item {
      font-family: "InterRegular", sans-serif!important;
      font-size: 12px!important;
      color: #9B9B9B!important;
      height: 100%;
    }
  }
  &__table_body {
    .table_row {
      min-height: 60px;
      height: max-content;
      width: 100%;
      display: flex;
      .row_item {
        font-size: 14px;
        &:first-of-type {
          font-size: 14px;
          font-family: "InterSemiBold", sans-serif;
          line-height: 18px;
        }
      }
    }
  }
  &__table_header, &__table_body {
    .table_row {
      width: 100%;
      .row_item {
        display: flex;
        align-items: center;
        width: 20%;
        &:first-of-type {
          width: 40%;
        }
      }
    }
  }
  .pagination-container {
    justify-content: center;
  }
  &__pagination {
  }
}
.build-campaign-panel {
  z-index: 1;
  display: flex;
  flex-flow: column;
  width: 358px;
  min-width: 358px;
  background-color: #FFFFFF;
  box-shadow: 1px 0 6px -4px rgb(0 0 0 / 10%);
  min-height: calc(100vh - 120px);
  height: 100%;
  padding: 30px 8px 30px 35px;
  box-sizing: border-box;
  position: relative;

  &.with-mobile-preview {
    padding: 32px 16px;
  }

  &__overlay {
    position: absolute;
    min-height: calc(100vh - 120px);
    width: 358px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(238,243,255,0.8);
  }
}
.step-mobile-preview {
  position: sticky;
  top: 50px;
  width: 315px;
  min-width: 315px;
  box-sizing: border-box;

  &__note {
    padding: 0 110px 0 19px;
    margin-bottom: 32px;
    color: #9B9B9B;
    font-family: "InterSemiBold", sans-serif;
    font-size: 12px;
    line-height: 16px;
  }

  &__mobile-preview {
    position: relative;
    min-height: 605px;
    display: flex;

    &:before {
      position: absolute;
      background: url("../../../../../assets/image/mobile_frame.svg") no-repeat;
      content: '';
      right: calc(50% - 163px);
      height: 651px;
      width: 326px;
    }

    &--mobile-panel {
      z-index: 3;
      width: 160px;
      height: 28px;
      position: absolute;
      background-color: #f4f4f5;
      border-radius: 0 0 20px 20px;
      top: 21px;
      left: calc(50% - 80px);
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        width: 38px;
        margin-bottom: 4px;
        margin-left: 20px;
        height: 6px;
        border-radius: 5px;
        background-color: #d5d5d5;
        margin-right: 10px;
      }

      span {
        margin-bottom: 5px;
        height: 6px;
        width: 6px;
        border: 1px solid #d5d5d5;
        border-radius: 50%;
        background-color: #f0f0f0;
      }
    }

    &--view {
      background: #6429B3;
      border-radius: 32px;
      margin: 24px 18px -19px;
      z-index: 1;
      width: 288px;
      display: flex;
      flex-flow: column;
      max-height: 600px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 3.5px;
        background: rgba(56, 56, 56, 0.5);
        border-radius: 3.5px;
      }
    }

    &--header {
      background: #7435C7;
      width: 100%;
      height: 66px;
      z-index: 2;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;

      &.body-background {
        background: #6429B3;
        height: unset;
      }
    }

    &--statusbar {
      width: 100%;
    }

    &--header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      font-family: "InterMedium", sans-serif;
      font-size: 10px;
      line-height: 22px;
      color: #FFFFFF;
      margin: 6px 0;

      & > img {
        width: 21px;
        height: 21px;
      }
    }

    &--body {
      color: #FFFFFF;
      width: 100%;
      height: max-content;
      padding: 37.5px 22.5px 40.5px;
      box-sizing: border-box;

      &.flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        height: 100%;
      }

      h2 {
        margin-bottom: 15px;
        font-family: "InterBold", sans-serif;
        font-size: 16.5px;
        line-height: 21px;
      }

      p {
        font-size: 10.5px;
        line-height: 15px;
      }

      strong {
        font-family: "InterBold", sans-serif;
        font-size: 13.5px;
        line-height: 16.5px;
      }

      .mobile-icon {
        border-radius: 6px;

        &.fixed-height {
          height: 152px;
        }
      }
    }

    &--footer {
      margin: auto 15px 35px;
    }
  }

  .continue-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F23EA6;
    border-radius: 23px;
    width: 100%;
    height: 34.5px;
    min-height: 34.5px;
    font-family: "InterSemiBold", sans-serif;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: #FFFFFF;

    &.disabled {
      background: rgba(#F23EA6, 0.5);
      color: rgba(#FFFFFF, 0.5);
    }

    &.progress-btn {
      background: rgba(#F23EA6, 0.5);
      color: rgba(#FFFFFF, 0.5);
      position: relative;

      p {
        z-index: 2;
      }

      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 59%;
        height: 100%;
        left: 0;
        top: 0;
        border-top-left-radius: 23px;
        border-bottom-left-radius: 23px;
        background: #F23EA6;
      }
    }
  }

  .image-wrapper {
    margin-top: 22.5px;
    position: relative;

    iframe {
      display: none;
    }

    &__icon {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      transform: translate(-50%, -50%);
    }

    &__duration {
      position: absolute;
      z-index: 10;
      bottom: 10px;
      left: 10px;
      background: rgba(#111111, 0.8);
      border-radius: 20px;
      padding: 0 5px;
      font-weight: 400;
      font-size: 10.5px;
      line-height: 15px;
      color: #FFFFFF;
    }
  }

  .input-text {
    margin-top: 30px;
    border-left: 1px solid #F23EA6;
    color: rgba(#FFFFFF, 0.3);
    font-weight: 400;
    font-size: 13.5px;
    padding: 4px 0;
  }

  .multiple-choices {
    margin-top: 30px;
    display: flex;
    flex-flow: column;
    row-gap: 5px;
    &__row {
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-weight: 600;
      font-size: 10.5px;
      & > img {
        width: 21px;
        height: 21px;
      }
    }
  }

  .rating-icon {
    width: 180px;
    margin: auto;
  }

  .yes-no {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 18px;
    margin: auto;
    & > div {
      width: 90px;
      height: 90px;
      background: rgba(#111111, 0.1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(#75DBCD, 0.5);
      font-family: "InterBold",sans-serif;
      text-transform: uppercase;
      font-size: 13.5px;
    }
  }

  .pictures-wrapper {
    margin-top: 22.5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6px;
    row-gap: 6px;
    width: 100%;
    box-sizing: border-box;
    &__block {
      width: 100%;
    }
  }

  .success-icon {
    width: 165px;
    margin: auto;
    display: block;
  }

  .products-wrapper {
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-wrapper {
    position: relative;

    &:nth-child(2) {
      z-index: 2;
      margin-left: -26.25px;
    }

    .mobile-icon {
      height: 90px;
      object-fit: cover;
      border-radius: 50%;
    }

    &__icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 25.5px;
      height: 25.5px;
    }
  }

  .success-text {
    margin-top: 30px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    row-gap: 15px;
  }

  .monetary-reward {
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "InterBold", sans-serif;
    font-size: 34.5px;
    text-align: center;
    letter-spacing: -1.53333px;

    &__icon {
      width: 26.25px;
      height: 26.25px;
      margin-right: 10.5px;
    }
  }
}
.info_bonus{
  display: flex;
  align-items: center;
  padding: 0 30px;
  .price_block_mini{
    margin-right: 30px;
  }
  .info{
    margin-right: 30px;
    display: flex;
    flex-flow: column;
    p{
      color: #383838;
      font-family: "InterSemiBold",sans-serif;
      font-size: 12px;
      line-height: 15px;
      padding-bottom: 7px;
    }
    span{
      color: #383838;
      font-family: "InterRegular",sans-serif;
      font-size: 16px;
      line-height: 20px;
      height: 38px;
      display: flex;
      align-items: center;
    }
  }
}
.distribution_wrapper {
  display: flex;
  width: 100vw;
  .distribution_container {
    display: flex;
    justify-content: center;
    width: calc(100% - 604px);
    padding: 30px 123px 80px;
    background-color: #F8F8F8;
    background-image: -webkit-radial-gradient(#d8d8d8 1px, transparent 0);
    background-image: radial-gradient(#d8d8d8 1px, transparent 0);
    background-size: 20px 20px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
}

.distribution {
  width: 100%;
  max-width: calc(835px - 100px);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 40px 67px 60px 100px;
  background: #FFFFFF;
  box-shadow: 0px 5px 7px -3px rgba(88, 88, 88, 0.2), 0px 12px 19px 1px rgba(92, 92, 92, 0.06), 0px 5px 23px 4px rgba(89, 89, 89, 0.05);
  border-radius: 8px;
  .title {
    font-family: "InterSemiBold", sans-serif;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #383838;
  }
  .description {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.177778px;
    color: #656565;
    margin: 24px 0 43px;
  }
  .distribution_row_wrapper {
    display: flex;
    min-height: 80px;
    width: 100%;
    margin: 10px 0;
    .row_name {
      font-size: 16px;
      font-family: "InterSemiBold", sans-serif;
      width: 153px;
      border-right: 2px solid #E9E9E9;
      margin-right: 40px;
      position: relative;
      img {
        position: absolute;
        top: 2px;
        left: -24px;
        cursor: help;
      }
    }
    .row_content {
      display: flex;
      flex-flow: column;
      width: calc(100% - 255px);
      margin-top: 7px;
      .item_radio {
        label {
          font-family: "InterSemiBold", sans-serif;
        }
      }
      .separator {
        margin: 2px 0 12px;
        height: 1px;
        width: 100%;
        background: #E2E5EB;
      }
      .switch_wrapper {
        margin-bottom: 25px;
      }
      .input_title_block_my_campaign {
        padding: 0;
        transition: all 0.3s linear;
        &.margin_bottom_25 {
          margin-bottom: 25px;
        }
        &.margin_bottom_15 {
          margin-bottom: 15px;
        }
        &.delivery-price {
          margin: 5px 0 20px 30px;
        }
        .distribution_line {
          display: flex;
          align-items: center;
          & > span {
            font-family: "InterRegular", sans-serif;
            margin: 0 10px;
            color: #9B9B9B;
            font-size: 12px;
          }
          .limit_input {
            width: 145px;
            margin-right: 20px;
            transition: all 0.3s linear;
          }
        }
        .time-to-finish-fields {
          display: flex;
          align-items: center;
          .price_block_input {
            margin-right: 20px;
            span {
              text-transform: uppercase;
            }
          }
        }
        .drag_img{
          width: 100%;
          display: flex;
          align-items: center;
          margin: 7px 0 20px;
          >span{
            margin-right: 10px;
            height: 100%;
            display: flex;
            align-items: center;
          }
          >p{
            display: flex;
            align-items: center;
            height: 25px;
            width: 25px;
            border-radius: 2px;
            margin-right: 15px;
            img{
              height: 25px;
              width: 25px;
              background-size: 100% 100%;
            }
          }
          >div{
            color: #383838;
            font-size: 12px;
            line-height: 15px;
            margin-right: 15px;
          }
          >button{
            border-radius: 4px;
            background: transparent;
            cursor: pointer;
            margin-right: 10px;
          }
        }
        textarea{
          resize: none;
          height: 136px;
          width: 100%;
          padding: 15px;
          box-sizing: border-box;
          border: 1px solid #CECECE;
          border-radius: 4px;
          background-color: #FFFFFF;
          color: #383838;
          font-family: "InterRegular",sans-serif;
          font-size: 12px;
          line-height: 15px;
          &::placeholder{
            color: #9B9B9B;
            font-size: 12px;
          }
          &:focus{
            border-color:#111111 ;
          }
          &::-webkit-scrollbar {
            width: 5px;
            height:5px;
          }
          &::-webkit-scrollbar-track {
            -webkit-border-radius: 2px;
            background: #ffffff;
          }
          &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 5px;
            border-radius: 5px;
            background: rgba(132, 148, 185,0.3);
          }
          &::-webkit-scrollbar-thumb:window-inactive {
            background: rgba(132, 148, 185,0.3);
          }
        }
        .select_wrapper {
          width: 123px;
          .select_component .select__control .select__single-value {
            padding-left: 0px !important;
          }
        }
        .price_block_input {
          position: relative;
          width: 145px;
          input {
            height: 38px;
            width: 145px;
            padding: 0 53px 0 15px;
            box-sizing: border-box;
            border: 1px solid #CECECE;
            border-radius: 4px;
            background-color: #FFFFFF;
            color: #383838;
            font-family: "InterRegular", sans-serif;
            font-size: 12px;
            line-height: 15px;
            &::placeholder {
              color: #9B9B9B;
              font-size: 12px;
            }
            &:focus {
              border-color: #111111;
            }
            &:disabled {
              cursor: not-allowed;
            }
          }
          span{
            font-family: "InterRegular", sans-serif;
            height: 36px;
            width: 53px;
            border-radius: 0 4px 4px 0;
            background-color: #F2F2F2;
            position: absolute;
            right: -4px;
            font-size: 12px;
            top: 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.small_one {
              width: 38px;
            }
          }
        }
      }
      .pickup_addresses_wrapper {
        margin-left: 30px;
      }
      .add_address {
        margin-left: 30px;
        width: calc(100% - 30px);
      }
    }
  }
  .save_button {
    margin-top: 50px;
  }
}

.distribution_description {
  margin-bottom: 4px;
  color: #9B9B9B;
  font-size: 12px;
  line-height: 15px;
}

.create-company-profile {
  width: 100%;
  height: max-content;
  &__header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6429B3;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 80px;
  }
}
.deliveries_wrapper {
  display: flex;
  width: 100vw;
  .deliveries_container {
    display: flex;
    flex-flow: column;
    width: calc(100% - 504px);
    padding: 50px 73px 200px;
    background-color: #F8F8F8;
    background-size: 20px 20px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .in_total_block {
    min-height: 47px;
    padding: 16px 0;
    border-radius: 8px;
    background-color: #EEF3FF;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    & > div {
      color: #324EA3;
      font-family: "InterSemiBold", sans-serif;
      font-size: 16px;
      letter-spacing: -0.18px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    & > p {
      color: #324EA3;
      font-size: 12px;
      letter-spacing: -0.1px;
    }
  }
  .deliveries_list_wrapper {
    display: flex;
    flex-flow: column;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px -2px rgba(88,88,88,0.3), 0 3px 13px 2px rgba(89,89,89,0.05);
    padding: 32px;
  }
  .search{
    position: relative;
    margin: 32px 0;
    input{
      box-sizing: border-box;
      height: 37px;
      width: 288px;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding: 0 20px 0 39px;
      font-family:"InterRegular",sans-serif;
      color: #111111;
      transition: all 0.3s linear;
      &::placeholder{
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus{
        border-color:#111111 ;
      }
    }
    button{
      display: flex;
      align-items: center;
      background: transparent;
      justify-content: center;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 15px;
      top: calc(50% - 8px);
    }
  }
  .table_container {
    .table_header,
    .table_body {
      .table_row {
        width: 100%;
        .row_item {
          display: flex;
          align-items: center;
          &:first-child {
            width: 30%;
          }
          &:nth-child(2) {
            width: 25%;
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:nth-child(4) {
            width: 20%;
            padding-right: 1%;
            box-sizing: border-box;
          }
          &:last-child {
            width: 5%;
          }
        }
      }
    }
    .table_header {
      user-select: none;
      .row_item {
        font-family: "InterRegular", sans-serif;
        font-size: 12px;
        color: #9B9B9B;
        height: 100%;
        &.with_sort_icon {
          cursor: pointer;
          img {
            margin-left: 4px;
          }
        }
      }
    }
    .table_body {
      .table_row {
        min-height: 55px;
        height: max-content;
        width: 100%;
        display: flex;
        flex-flow: column;
        .main_info {
          min-height: 55px;
          height: 100%;
          width: 100%;
          display: flex;
        }
        .additional_info {
          height: 0;
          overflow: hidden;
          transition: all 0.1s linear;
          border-radius: 8px;
          background-color: #F8F8F8;
          width: calc(100% - 64px);
          padding: 0 32px;
          h6 {
            font-size: 12px;
            line-height: 13px;
            margin-bottom: 4px;
            display: block;
          }
          p {
            font-size: 14px;
            font-family: "InterSemiBold", sans-serif;
            line-height: 18px;
          }
          .in_line_info {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 64px;
          }
          &.expanded {
            padding: 32px;
            height: max-content;
            margin: 4px 0 16px;
          }
        }
      }
      .row_item {
        &:first-child {
          justify-content: center;
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          & > p {
            display: block;
            margin-bottom: 2px;
            font-size: 14px;
            color: #383838;
            font-family: "InterSemiBold", sans-serif;
          }
          & > span {
            font-family: "InterMedium", sans-serif;
            font-size: 12px;
            color: #9B9B9B;
          }
        }
        &:nth-child(2) {
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          justify-content: center;
          & > p {
            font-size: 14px;
            display: flex;
            align-items: center;
            font-family: "InterMedium", sans-serif;
          }
        }
        &:nth-child(3) {
          color: #6429B3;
          font-size: 14px;
          font-family: "InterSemiBold", sans-serif;
        }
        &:nth-child(4) {
          justify-content: center;
        }
        &:last-child {
          justify-content: center;
          cursor: pointer;
          min-height: 55px;
          height: 100%;
          user-select: none;
          img {
            cursor: pointer;
            transition: all 0.3s linear;
            &.active {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  .pagination-container {
    justify-content: center;
  }
}

.deliveries_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;
  .title {
    font-family: "InterSemiBold", sans-serif;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #383838;
  }
}

.delivery-status {
  &__updated {
    margin-top: 2px;
    color: #9B9B9B;
    font-size: 12px;
    line-height: 16px;
    font-family: "InterRegular", sans-serif;
    &--value {
      color: #656565;
    }
  }
}
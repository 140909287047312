.input_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  & > div {
    color: #9B9B9B;
    font-size: 12px;
    line-height: 15px;
  }
}
.select_component {
  height: 100%;
  &.select--is-disabled {
    cursor: not-allowed!important;
    opacity: 0.7!important;
  }
  & ~ .date_wrapper {
    margin-left: 20px;
  }
  .select__control {
    height: 100%;
    background-color: #ffffff;
    color: #383838!important;
    font-family: "InterRegular", sans-serif!important;
    font-size: 12px;
    border: 1px solid #CECECE;
    border-radius: 4px;
    padding-left: 8px!important;
    &:hover {
      cursor: pointer;
      border: 1px solid #CECECE!important;
    }
    .select__placeholder {
      color: #9B9B9B;
      font-family: "InterRegular", sans-serif;
      font-size: 12px;
    }
    .select__single-value {
      color: #383838;
      margin: 0!important;
      padding-left: 2px!important;
    }
    .select__indicator-separator {
      background: none;
    }
    .select_indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px!important;
      transition: 0.1s all linear;
    }
    .indicator_active {
      transform: rotate(180deg);
    }
  }
  .select__control--is-focused {
    box-shadow: none;
    border: 1px solid #111111!important;
    &:hover{
      border: 1px solid #111111!important;
    }
    .indicator_active {
      svg {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
  .select__menu {
    z-index: 9999;
    border-radius: 6px!important;
    background-color: #FFFFFF;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.25)!important;
    border: none!important;
    margin-top: 4px!important;
    .select__menu-list {
      font-size: 12px;
      font-family: "InterRegular", sans-serif!important;
      color: #383838;
      background: transparent;
      .select__option{
        min-height: 31px;
        background: transparent;
        color: #383838;
        cursor: pointer;
        &:hover{
          background-color: rgba(32,69,105,0.05);
          color: #383838;
        }
      }
      .select__option--is-disabled {
        padding: 0!important;
      }
      .select__option--is-selected {
        color: #383838;
        font-family: "InterSemiBold", sans-serif!important;
        background-color: rgba(32,69,105,0.05);
      }
      .status_select{
        padding: 0 12px;
        display: flex;
        align-items: center;
        background: transparent;
        &:hover{
          background: transparent;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        height:5px;
      }
      &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: rgba(132, 148, 185,0.3);
      }
      &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(132, 148, 185,0.3);
      }
    }
  }
  input{
    height: unset;
  }
  &__add-new-option {
    color: #F23EA6;
    font-size: 10px;
    font-family: "InterSemiBold", sans-serif;
    width: calc(100% - 24px);
    height: 100%;
    padding: 8px 12px;
  }
  &__label-start-adornment {
    color: #9B9B9B;
    font-size: 12px;
    line-height: 13px;
    margin-right: 20px;
  }
}
.color_picker_wrapper{
  position: relative;
  .color_field {
    padding: 8px 15px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #CECECE;
    border-radius: 4px;
    width: 215px;
    cursor: pointer;
    & > div {
      display: flex;
      align-items: center;
      .color_preview {
        width: 38px;
        border-radius: 2px;
        margin-right: 12px;
      }
      p {
        font-size: 14px;
      }
    }
    .arrow_wrapper {
      cursor: pointer;
      height: 100%;
      .arrow {
        transition: all 0.2s linear;
        &.up {
          transform: rotate(180deg);
        }
      }
    }
  }
  .colors_container {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 238px;
    max-height: 120px;
    padding: 8px 4px 8px 8px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 6px 0 rgba(43,44,51,0.3);
    z-index: 1000;
    overflow: auto;
    box-sizing: border-box;
    margin-top: 5px;
    .clear-icon {
      height: calc(80%);
    }
    .color_preview {
      width: 38px;
      border-radius: 2px;
      margin: 0 4px 4px 0;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .current_color {
        position: absolute;
        border-radius: 4px;
        top: -3px;
        left: -3px;
        width: 42px;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
      height:5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;

      background: #EDF2F8;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: grey;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: grey;
    }
  }
}
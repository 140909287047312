.data_source_container{
  display: flex;
  justify-content: center;
  padding: 64px;
  width: 100%;
  background: rgba(242, 242, 242, 0.6);
  .back{
    color: #F23EA6;
    font-family: "InterBold",sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 50px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
  >div{
    height: max-content;
    width: 100%;
    background-color: #FFFFFF;
    padding: 40px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border-radius: 8px;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
  }
  .title{
    font-family: "InterSemiBold",sans-serif;
    font-size: 24px;
    line-height: 29px;
    color: #383838;
  }
  .btn_wrapper {
    display: flex;
    .default_button_wrapper {
      display: flex;
      .default_button.auth{
        margin-right: 20px;
        height: 41px;
        width: 209px;
        min-width: 153px;
        font-size: 14px;
      }
    }
    &.go_to_users {
      width: 100%;
      justify-content: center;
    }
  }
  .api_key_wrapper {
    p {
      color: #383838;
      font-size: 16px;
      line-height: 24px;
      strong {
        font-weight: normal;
        font-family: "InterSemiBold", sans-serif;
      }
    }
    .default_button_wrapper {
      margin-top: 30px;
    }
    &.regenerate_one {
      margin-bottom: 30px;
    }
  }
  .generate_api_key_form {
    width: 50%;
    min-width: 520px;
    .error_text {
      min-height: 20px;
    }
    .label {
      font-size: 14px;
      font-family: "InterSemiBold", sans-serif;
      margin-bottom: 10px;
    }
  }
  .warning_api_key_generation {
    width: calc(100% - 60px);
    border-radius: 8px;
    background-color: #F2F2F2;
    padding: 30px;
    margin: 20px 0 30px;
    strong {
      font-weight: normal;
      font-family: "InterSemiBold", sans-serif;
    }
  }
  .api_key_separator {
    margin: 50px 0;
  }
}

.add-api-data-source {
  .title {
    margin-bottom: 40px;
  }

  .btn_wrapper {
    margin-top: 50px;
  }

  p {
    color: #383838;
    font-size: 16px;
    line-height: 24px;
  }

  h4 {
    display: block;
    margin: 60px 0 32px;
    font-family: "InterSemiBold", sans-serif;
  }

  strong {
    color: #F23EA6;
    font-family: "InterSemiBold", sans-serif;
    line-height: 18px;
    cursor: pointer;
  }

  a {
    color: inherit;
    cursor: unset;
  }
}
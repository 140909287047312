.profile_wrapper {
  display: flex;
  flex-flow: column;
  .profile_tabs_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 16px 0 32px;
    border-bottom: 1px solid #E9E9E9;
    .tabs_wrapper {
      width: 100%;
      margin-bottom: -1px;
    }
  }
}

.general_wrapper {
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
  display: flex;
  flex-flow: column;
  padding: 40px 146px 50px 70px;
  .general_info_line {
    display: flex;
    margin-bottom: 30px;
    .line_name {
      width: 15%;
      font-family: "InterSemiBold", sans-serif;
      font-size: 16px;
      line-height: 20px;
      border-right: 2px solid #E9E9E9;
      padding: 6px 0;
      & > p {
        max-width: 100px;
      }
    }
    .profile_form_wrapper {
      width: calc(85% - 42px);
      display: flex;
      flex-flow: column;
      padding: 5px 0 10px 42px;
      button {
        position: absolute;
        color: #F23EA6;
        font-family: "InterMedium", sans-serif;
        font-size: 10px;
        height: 37px;
      }
      .label {
        font-size: 12px;
        font-family: "InterSemiBold", sans-serif;
        line-height: 15px;
        margin-bottom: 13px;
      }
      .profile_field {
        margin-right: 20px;
        .field {
          display: flex;
          align-items: center;
          position: relative;
          height: 37px;
          width: 100%;
          border: 1px solid #CECECE;
          border-radius: 4px;
          background-color: #FFFFFF;
          p {
            font-size: 12px;
            margin-left: 15px;
            &.with_opacity {
              opacity: 0.5;
            }
          }
          button {
            right: 15px;
          }
        }
      }
      & > div {
        display: flex;
        & > div {
          width: 100%;
          &.profile_photo_wrapper {
            display: flex;
            .avatar_wrapper {
              width: 96px;
              height: 96px;
              margin-left: 0;
            }
            img {
              width: 96px;
              height: 96px;
              border-radius: 8px;
            }
            & > div {
              width: 100%;
              display: flex;
              flex-flow: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-left: 20px;
              p {
                font-family: "InterSemiBold", sans-serif;
                font-size: 12px;
                margin: 14px 0 18px;
                display: flex;
                align-items: center;
                .tooltip-question-icon {
                  width: 12px;
                  height: 12px;
                }
              }
              .buttons_wrapper {
                display: flex;
                width: 100%;
                height: 27px;
                & > div {
                  button {
                    position: relative;
                  }
                }
              }
            }
          }
        }
        form {
          width: calc(100% - 20px);
          position: relative;
          margin-top: 14px;
          padding-right: 20px;
          .custom_input_wrapper .custom_input input {
            padding: 0 80px 0 15px;
          }
          .custom_input_error {
            &:before {
              right: 50px;
            }
          }
          button {
            top: 28px;
            right: 34px;
          }
        }
      }
    }
    .social_buttons_wrapper {
      display: flex;
      flex-flow: column;
      width: calc(85% - 62px);
      padding: 5px 20px 10px 42px;
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .account_wrapper {
          display: flex;
          img {
            margin-right: 10px;
          }
          & > div {
            display: flex;
            align-items: flex-start;
            flex-flow: column;
            justify-content: center;
            div {
              font-size: 14px;
              font-family: "InterSemiBold", sans-serif;
              line-height: 20px;
            }
            span {
              font-size: 12px;
              &.with_opacity {
                opacity: 0.5;
              }
            }
          }
        }
      }
      hr {
        margin: 24px 0;
        width: 100%;
      }
    }
  }
}

.companies_wrapper {
  .companies_content{
    display: flex;
    flex-flow: column;
    >div{
      position: relative;
      margin-bottom: 30px;
      width: 100%;
      border-radius: 8px;
      background-color: #FFFFFF;
      box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
      display: flex;
      justify-content: space-between;
      .content{
        width: 100%;
        display: flex;
        align-items: center;
      }
      .img_block{
        margin-right: 30px;
        height: 111px;
        width: 133px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px 0 0 8px;
        img{
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          max-width: 133px;
        }
      }
      .user_info{
        display: flex;
        flex-flow: column;
        position: relative;
        & > p {
          position: absolute;
          top: -44px;
          border-radius: 0 0 2px 2px;
          font-family: "InterMedium", sans-serif;
          font-size: 10px;
          line-height: 16px;
          text-transform: uppercase;
          text-align: center;
          background-color: rgba(237, 142, 84, 0.1);
          width: 60px;
          color: #ED8E54;
          &.is_owner {
            background: rgba(100, 41, 179, 0.1);
            color: #6429B3;
          }
          &.is_archived {
            background-color: #CECECE;
            color: #FFFFFF;
            left: 70px;
            width: 72px;
          }
        }
        & > div{
          width: 100%;
          color: #383838;
          font-family: "InterSemiBold",sans-serif;
          font-size: 20px;
          line-height: 24px;
          max-width: 600px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .more_info_btn{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 31px;
        width: 100%;
        img{
          margin: 0 10px;
          cursor: pointer;
        }
        .dialog_close_button {
          margin-left: 40px;
          width: 49px;
        }
        .btn_wrapper {
          display: flex;
          align-items: center;
          .switch_btn {
            margin: 0!important;
          }
          .leave_btn {
            margin-left: 16px;
          }
        }
      }
      .menu {
        z-index: 1000;
        right: 30px;
        top: 81px;
        position: absolute;
        box-sizing: border-box;
        height: max-content;
        width: 228px;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
        color: #000000;
        padding: 10px 0;
        button {
          width: 100%;
          padding: 10px 33px 10px 16px;
          text-align: left;
          font-size: 14px;
          font-family: "InterRegular", sans-serif;
          display: flex;
          align-items: center;
          &:hover {
            background-color: rgba(0,0,0,0.05);
          }
          img {
            width: 12px;
            margin-right: 12px;
          }
        }
      }
    }
  }
  .pagination_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .active_one {
    position: absolute;
    color: #9B9B9B!important;
    font-family: "InterSemiBold",sans-serif!important;
    font-size: 11px!important;
    bottom: -26px;
    overflow: unset!important;
    text-transform: uppercase;
    img {
      margin-right: 8px;
    }
  }
  .company_balance {
    text-align: end;
    margin-right: 40px;
    .balance_label {
      margin-bottom: 7px;
      color: #9B9B9B;
      font-size: 14px;
      line-height: 17px;
    }
    .balance_value {
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
      font-size: 20px;
      display: flex;
      align-items: center;
    }
  }
}

.add_company_wrapper, .edit_company_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5% 0;
  background: rgba(242, 242, 242, 0.6);
}

.company-form {
  width: 550px;
  height: max-content;
  background-color: #FFFFFF;
  padding: 70px 105px 60px;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
  .title {
    color: #383838;
    font-family: "InterSemiBold",sans-serif;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 8px;
  }
  .info {
    color: #383838;
    font-size: 18px;
    margin: 40px 0 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    .tooltip-question-icon {
      width: 16px;
      height: 16px;
    }
  }
  .add_address {
    text-align: center;
  }
  .address_controls {
    min-width: 68px;
  }
  .input_wrapper {
    position: relative;
    width: 100%;
    input {
      width: 100%;
      box-sizing: border-box;
      height: 48px;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding: 0 15px;
      font-family: "InterRegular",sans-serif;
      color: #111111;
      transition: all 0.3s linear;
      &::placeholder{
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus{
        border-color:#111111;
      }
    }
    p {
      padding-top: 6px;
      padding-bottom: 40px;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 15px;
    }
    textarea {
      resize: none;
      height: 136px;
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      color: #383838;
      font-family: "InterRegular",sans-serif;
      font-size: 12px;
      line-height: 15px;
      &::placeholder{
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus{
        border-color:#111111 ;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height:5px;
      }
      &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: rgba(132, 148, 185,0.3);
      }
      &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(132, 148, 185,0.3);
      }
    }
  }
  .select_wrapper {
    width: 100%;
  }
  .default_button_wrapper {
    margin: 60px 0;
    .default_button.auth{
      height: 40px;
      width: 153px;
      min-width: 153px;
      font-size: 14px;
    }
  }
  .upload_block {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 119px;
    width: 100%;
    border: 2px dashed #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    cursor: pointer;
    img{
      margin-bottom: 22px;
    }
    span{
      color: #9B9B9B;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .drag_img{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    >span{
      margin-right: 10px;
      height: 100%;
      display: flex;
      align-items: center;
    }
    >p{
      display: flex;
      align-items: center;
      height: 25px;
      width: 25px;
      border-radius: 2px;
      margin-right: 15px;
      img{
        height: 25px;
        width: 25px;
        background-size: 100% 100%;
      }
    }
    >div{
      color: #383838;
      font-size: 12px;
      line-height: 15px;
      margin-right: 15px;
    }
    >button{
      border-radius: 4px;
      background: transparent;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .container {
    width: 100%;
  }
  .back {
    color: #F23EA6;
    font-family: "InterBold",sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
  }
  .validation-error {
    position: absolute;
    left: 0;
    bottom: -16px;
    color: #FF1919;
    font-size: 12px;
  }
}

.delivery-companies {
  width: 100%;
  position: relative;
  z-index: 100;
  &__selector {
    height: 48px;
    border: 1px solid #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    width: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 15px;
    box-sizing: border-box;
  }
  &__expand-icon {
    transition: all 0.1s linear;
    user-select: none;
    &.is-open {
      transform: rotate(180deg);
    }
  }
  &__options {
    position: absolute;
    width: 100%;
    left: 0;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 6px 0 rgba(43,44,51,0.3);
    display: flex;
    flex-flow: column;
    padding: 22px 20px;
    box-sizing: border-box;
    user-select: none;
    &--company {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }
    &--info {
      display: flex;
      flex-flow: column;
      margin-top: 8px;
      font-size: 14px;
      line-height: 18px;
      margin-left: 4px;
    }
    &--input-label {
      margin: 20px 0 13px;
      font-size: 12px;
      font-family: "InterSemiBold",sans-serif;
      line-height: 15px;
    }
    &--separator {
      margin: 21.5px 0;
      height: 1px;
      width: 100%;
      background: #E2E5EB;
    }
    &--price-input {
      width: 160px!important;
      input {
        height: 37px!important;
      }
      &::after {
        content: "€";
        position: absolute;
        right: 1px;
        top: 1px;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 0 4px 4px 0;
        background-color: #F2F2F2;
      }
    }
  }
}

.add_company_wrapper {
  .select_wrapper {
    height: 48px;
  }
}

.edit_company_wrapper{
  .info {
    width: 100%;
    justify-content: flex-start;
    font-family: "InterSemiBold", sans-serif;
    font-size: 12px;
    margin: 30px 0 12px;
  }
  .drag_img {
    margin-right: auto;
  }
  .add_address {
    text-align: start;
  }
  .input_wrapper {
    input {
      height: 37px;
      border: 1px solid #CECECE;
    }
  }
  .delivery-companies {
    &__selector {
      height: 37px;
      font-size: 12px;
    }
  }
}

.check-wrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #383838;
  p {
    a {
      color: #F23EA6;
    }
  }
}

.rewards_wrapper{
  position: absolute;
  top: -17px;
  left: -17px;
  z-index: 1;
  .block_wrapper{
    border: 2px solid #FE8F1D;
  }
}

.price_block_big {
  padding: 0 30px;
  .title {
    display: flex;
    align-content: center;
    padding-bottom: 7px;
    span {
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin-right: 5px;
      cursor: auto;
    }
  }

  .price_block_input {
    position: relative;
    width: 169px;
    input {
      height: 38px;
      width: 169px;
      padding: 0 45px 0 15px;
      box-sizing: border-box;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      color: #383838;
      font-family: "InterRegular", sans-serif;
      font-size: 12px;
      line-height: 15px;
      &::placeholder {
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus {
        border-color: #111111;
      }
    }
    span{
      height: 36px;
      width: 38px;
      border-radius: 0 3px 3px 0;
      background-color: #F2F2F2;
      position: absolute;
      right: 1px;
      font-size: 12px;
      top: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.price_block_mini {
  .title {
    display: flex;
    align-content: center;
    padding-bottom: 7px;
    span {
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin-right: 5px;
    }
  }
  .price_block_input {
    position: relative;
    width: 129px;
    input {
      height: 38px;
      width: 129px;
      padding: 0 70px 0 15px;
      box-sizing: border-box;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      color: #383838;
      font-family: "InterRegular", sans-serif;
      font-size: 12px;
      line-height: 15px;
      &::placeholder {
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus {
        border-color: #111111;
      }
    }
    span{
      font-size: 12px;
      height: 36px;
      width: 62px;
      border-radius: 0 3px 3px 0;
      background-color: #F2F2F2;
      position: absolute;
      right: 1px;
      top: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}



.cost {

  &__wrapper {
    display: flex;
    width: 100vw;
  }

  &__container {
    display: flex;
    flex-flow: column;
    row-gap: 32px;
    align-items: flex-start;
    width: calc(100% - 504px);
    min-width: 900px;
    padding: 50px 73px 200px;
    background-color: #F8F8F8;
    background-size: 20px 20px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }

  &__title-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-family: "InterSemiBold", sans-serif;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #383838;
  }

  &__card {
    box-sizing: border-box;
    width: 100%;
    padding: 32px 32px 48px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px -2px rgba(88,88,88,0.3), 0 3px 13px 2px rgba(89,89,89,0.05);

    .tabs_wrapper {
      margin-bottom: 32px;
      border-bottom: 1px solid #E9E9E9;
    }
  }

  &__info-box {
    margin-bottom: 32px;
    border-radius: 8px;
    background-color: #EEF3FF;
    box-sizing: border-box;
    width: 100%;
    padding: 24px;
    display: flex;
    column-gap: 24px;

    &--icon {
      margin-top: 4px;
      height: 24px;
      width: 24px;
      min-width: 24px;
    }

    &--text {
      color: #324EA3;
      font-size: 16px;
      letter-spacing: -0.18px;
      line-height: 20px;
    }
  }

  &__totals-box {
    margin-bottom: 48px;
    border-radius: 8px;
    background-color: #F8F8F8;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 48px;
    display: flex;
    flex-flow: column;
    row-gap: 24px;

    &--wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .purple_btn {
        min-width: 190px!important;
        letter-spacing: 0;
      }
    }

    &--warning {
      color: #9B9B9B;
      font-size: 14px;
      line-height: 20px;
    }

    &--item {
      padding-right: 32px;
      margin-right: 32px;
      border-right: 1px solid #E2E5EB;

      &:last-of-type {
        padding-right: 0;
        border-right: none;
      }
    }

    &--label {
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      column-gap: 5px;
      font-size: 12px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 16px;
      color: #9B9B9B;

      & > img {
        height: 12px;
        width: 12px;
        cursor: help;
      }
    }

    &--value {
      color: #383838;
      font-family: "InterBold", sans-serif;
      font-size: 24px;
      line-height: 32px;

      & > span {
        font-family: "InterMedium", sans-serif;
        color: #9B9B9B;
      }
    }
  }

  &__lines {
    padding: 0 48px;
    box-sizing: border-box;
    width: 100%;
  }

  &__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 24px 0;
    border-bottom: 1px solid #E2E5EB;

    &.headline {
      padding: 0 0 32px;
    }

    &--medium {
      font-size: 16px;
      font-family: "InterMedium", sans-serif;
      line-height: 24px;
    }

    &--bold {
      font-size: 16px;
      font-family: "InterBold", sans-serif;
      line-height: 24px;
    }
  }

  &__approve-block {
    margin-top: 48px;
    color: #9B9B9B;
    font-size: 14px;
    line-height: 20px;

    .active {

      strong {
        cursor: pointer;
        color: #F23EA6;
      }
    }

    .inactive {
      strong {
        color: #656565;
      }
    }
  }

  &__max-campaign-cost-in-dialog {
    margin-top: 32px;
    border-radius: 8px;
    background-color: #F8F8F8;
    padding: 24px 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    row-gap: 8px;

    &--label {
      font-family: "InterMedium", sans-serif;
      font-size: 14px;
      line-height: 20px;
    }

    &--value {
      font-family: "InterBold", sans-serif;
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__publish-block {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .purple_btn {
      min-width: 190px!important;
      letter-spacing: 0;
    }

    &--approved-by {
      display: flex;
      align-items: center;
      column-gap: 4px;
      color: #9B9B9B;
      font-size: 14px;
      line-height: 18px;


      strong {
        color: #656565;
        font-family: "InterRegular", sans-serif;
        font-weight: normal;
      }

      & > img {
        min-width: 16px;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.sharing-via-link {
  &__title {
    margin-bottom: 32px;
    color: #656565;
    font-family: "InterSemiBold", sans-serif;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
  .table_container {
    .table_header {
      user-select: none;
      .row_item {
        font-family: "InterRegular", sans-serif;
        font-size: 12px;
        color: #9B9B9B;
        height: 100%;
      }
    }
    .table_body {
      .table_row {
        min-height: 55px;
        height: max-content;
        width: 100%;
        display: flex;
      }
    }
    .fb_table_header,
    .fb_table_body {
      .table_row {
        width: 100%;
        .row_item {
          display: flex;
          align-items: center;
          width: 20%;
          &:last-child {
            justify-content: center;
          }
        }
      }
    }
    .link_table_header,
    .link_table_body {
      .table_row {
        width: 100%;
        .row_item {
          display: flex;
          align-items: center;
          width: 33%;
        }
      }
    }
  }
  .pagination-container {
    justify-content: center;
  }
  .used_at {
    color: #383838;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
  .reward {
    color: #069284;
    font-size: 14px;
    font-family: "InterSemiBold", sans-serif;
    letter-spacing: 0;
    line-height: 18px;
  }
  .status {
    padding: 7px 16px;
    border-radius: 16px;
    font-size: 14px;
    font-family: "InterSemiBold", sans-serif;
    letter-spacing: 0;
    line-height: 18px;
    text-transform: capitalize;
    &.joined {
      background-color: #EEF3FF;
      color: #324EA3;
    }
    &.started, &.finished {
      background-color: #F8F2FF;
      color: #6429B3;
    }
    &.pending {
      background-color: #FFF3E7;
      color: #FE8F1D;
    }
    &.paid {
      background-color: #DEFFEE;
      color: #069284;
    }
  }
  .post-must-be-available {
    font-size: 14px;
    line-height: 18px;
    color: #383838;
    & > span {
      font-family: "InterMedium", sans-serif;
    }
  }
  .open-post {
    color: #F23EA6;
    font-family: "InterMedium", sans-serif;
    font-size: 14px;
    & > img {
      margin-left: 6px;
    }
  }
}

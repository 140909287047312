.responses_wrapper{
  .block_wrapper{
    border: 2px solid #069284;
  }
}

.short_text_wrapper{
  position: absolute;
  top: -17px;
  left: -17px;
  z-index: 1;
  .textarea_description_block_my_campaign{
    padding: 0 30px;
  }
}
.products_title_block{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .select_wrapper {
    width: 204px!important;
  }
  .btn_wrapper {
    display: flex;
    position: relative;
    @media (max-width: 1400px) {
      flex-flow: column;
      row-gap: 12px;
    }
    .default_button_wrapper {
      margin-left: 16px;
    }
    .menu {
      z-index: 1000;
      right: 0;
      top: 45px;
      position: absolute;
      box-sizing: border-box;
      height: max-content;
      width: 200px;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
      color: #000000;
      button {
        width: 100%;
        padding: 20px 30px;
        text-align: left;
        font-size: 14px;
        font-family: "InterSemiBold", sans-serif;
        display: flex;
        align-items: center;
        &:hover {
          background-color: rgba(0,0,0,0.05);
        }
        img {
          width: 12px;
          margin-right: 12px;
        }
      }
    }
  }
  .block{
    display: flex;
    flex-flow: column;
    .title{
      color: #383838;
      font-size: 24px;
      line-height: 29px;
      font-family: "InterSemiBold", sans-serif;
      margin-bottom: 38px;
    }
    .title_panel{
      display: flex;
      align-items: center;
      .search{
        position: relative;
        margin-right: 20px;
        input{
          box-sizing: border-box;
          height: 37px;
          width: 288px;
          border: 1px solid #CECECE;
          border-radius: 4px;
          background-color: #FFFFFF;
          padding: 0 20px 0 39px;
          font-family:"InterRegular",sans-serif;
          color: #111111;
          transition: all 0.3s linear;
          &::placeholder{
            color: #9B9B9B;
            font-size: 12px;
          }
          &:focus{
            border-color:#111111 ;
          }
        }
        button{
          display: flex;
          align-items: center;
          background: transparent;
          justify-content: center;
          position: absolute;
          width: 16px;
          height: 16px;
          left: 15px;
          top: calc(50% - 8px);
        }
      }
      .select_wrapper{
        width: 194px;
        margin-right: 20px;
      }
    }
  }
}

.products_content{
  display: flex;
  flex-flow: column;
  min-width: 880px;
  >div{
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
    display: flex;
    justify-content: space-between;
    .content{
      width: calc(100% - 80px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .product-status {
        position: absolute;
        left: 164px;
        top: 0;
        padding: 2px 10px;
        font-family: "InterMedium", sans-serif;
        font-size: 10px;
        line-height: 12px;
        border-radius: 0 0 2px 2px;
        text-transform: uppercase;
        color: #6429B3;
        background-color: rgba(100, 41, 179, 0.1);
      }
    }
    .product_block {
      display: flex;
      align-items: center;
      width: 80%;
      .img_block{
        margin-right: 30px;
        height: 111px;
        width: 133px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px 0 0 8px;
        img{
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          max-width: 133px;
        }
      }
      .product_info{
        display: flex;
        flex-flow: column;
        position: relative;
        width: calc(100% - 163px);
        >div{
          width: 100%;
          &:first-of-type {
            color: #383838;
            font-family: "InterSemiBold",sans-serif;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 8px;
            max-width: 600px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .description{
          font-size: 14px;
          line-height: 17px;
          color: #9B9B9B;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .data_info{
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      margin-left: 50px;
      width: calc(20% - 50px);
      span{
        color: #9B9B9B;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 14px;
      }
      p{
        color: #383838;
        font-family: "InterSemiBold",sans-serif;
        font-size: 20px;
      }
    }
    .more_info_btn{
      display: flex;
      align-items: center;
      margin-right: 31px;
      img{
        margin: 0 10px;
        cursor: pointer;
      }
    }
    .menu {
      z-index: 1000;
      right: 30px;
      top: 86px;
      position: absolute;
      box-sizing: border-box;
      height: max-content;
      width: 213px;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
      color: #000000;
      button {
        width: 100%;
        padding: 10px 52px 10px 18px;
        text-align: left;
        font-size: 14px;
        font-family: "InterRegular", sans-serif;
        display: flex;
        align-items: center;
        &:hover {
          background-color: rgba(0,0,0,0.05);
        }
        img {
          width: 12px;
          margin-right: 12px;
        }
      }
    }
  }
}

.products_wrapper {
  .pagination_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.product_delete_dialog {
  .dialog_name {
    color: #F23EA6;
    font-family: "InterSemiBold", sans-serif;
    font-size: 32px;
    margin-bottom: 24px;
  }
  .dialog_title {
    text-align: center;
  }
  .buttons_wrapper {
    justify-content: space-evenly;
  }
}
.phone-number-input {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &__flag-and-code {
    position: absolute;
    left: 20px;
    top: 51%;
    transform: translate(0, -51%);
    display: flex;
    align-items: center;
    height: 24px;
    z-index: 10;

    &--selector {
      width: 40px;
      height: 24px;
      position: relative;
      &:before {
        content: '\25bc';
        position: absolute;
        font-size: 6px;
        height: 6px;
        width: 6px;
        z-index: 11;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #383838;
      }
      .select_component {
        height: 24px;
        width: 100%;
        .select__control {
          min-height: 24px;
          padding: 0!important;
          border: 0!important;
          .select__indicators {
            display: none;
          }
        }
        .select__value-container {
          padding: 0!important;
          height: 24px;
          width: 100%;
          & > input {
            position: absolute;;
          }
        }
        .select__single-value {
          max-width: 100%;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &--separator {
      margin: 0 8px;
      height: 100%;
      width: 1px;
      background-color: #CECECE;
    }

    &--code {
      color: #000000;
      font-size: 12px;
    }
  }

  &__input {
    padding-left: 110px!important;
  }

  &__error {
    position: absolute;
    left: 0;
    bottom: -16px;
    color: #FF1919;
    font-size: 12px;
  }
}
/* autoprefixer: off */
body{
  overflow: auto;
  box-sizing: border-box;
  background: #FFFFFF;
  color: #111111;
  font-family: "InterRegular",sans-serif;
  &::-webkit-scrollbar {
    width: 17px;
    height: 17px;
  }
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(132, 148, 185, 0.3);
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(132, 148, 185, 0.3);
  }
}

.page_all{
  display: flex;
  flex-flow: column;
  min-width: 1300px;
}

.page{
  display: flex;
  position: relative;
  .MuiLinearProgress-root {
    position: absolute;
    width: 100%;
    z-index: 1000;
  }
}

.page_wrapper{
  display: flex;
  flex-flow: column;
  width: calc(100% - 320px);
  background: #F8F8F8;
  padding: 50px 68px 50px 64px;
  box-sizing: border-box;
}

a, button{
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  text-decoration: none;
  background: transparent;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.text_hover {
  cursor: pointer;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  &:hover{
    opacity: 0.5;
  }
}

.yellow_box {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 30px;
  width: 100%;
  border-radius: 8px;
  background-color: #DEFFEE;
  color: #30837B;
  font-size: 16px;
  line-height: 26px;
  &.max_width_460 {
    max-width: 400px;
  }
  &.max_width_520 {
    max-width: 480px;
  }
}

.loader_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.card_hover {
  cursor: pointer;
  -webkit-transition: all ease .2s;
  -moz-transition: all ease .2s;
  -ms-transition: all ease .2s;
  -o-transition: all ease .2s;
  transition: all ease .2s;
  &:hover{
    transform: scale(1.01);
  }
}

.no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

hr {
  background-color: #E9E9E9;
  height: 1px;
  width: 100%;
}

.rotated_90 {
  transform: rotate(180deg);
}

.transition_ease {
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}

.table_container{
  .table_row {
    border-bottom: 1px solid #E2E5EB;
  }
  .table_header{
    .row_item{
      color: #030303;
      font-family: "InterBold", sans-serif;
    }
    .table_row{
      width: 100%;
      display: flex;
      align-items: center;
      height: 44px;
    }
  }
  .table_body{
    .row_item{
      font-size: 12px;
    }
    .table_row{
      width: 100%;
      display: flex;
      align-items: center;
      height: 55px;
      text-decoration: none;
    }
    .no_items {
      height: 33px;
      display: flex;
      align-items: center;
    }
  }
}

.overflowed_p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inactive_hover {
  cursor: not-allowed!important;
}

.margin_top_30 {
  margin-top: 30px;
}

.my_campaign_wrapper{
  display: flex;
  width: 100%;
  .my_campaign_content{
    width: 100%;
  }
}

.wrapper_block_my_campaign{
  display: flex;
  justify-content: center;
  .block_wrapper{
    padding-bottom: 40px;
    width: 640px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
  }
  .head_block{
    padding: 15px 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #E9E9E9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn_head{
      button{
        margin-right: 10px;
        &:hover {
          opacity: 0.7;
          -webkit-transition: all ease .3s;
          -moz-transition: all ease .3s;
          -ms-transition: all ease .3s;
          -o-transition: all ease .3s;
          transition: all ease .3s;
        }
        &:disabled {
          cursor: default;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    .title_head{
      display: flex;
      align-items: center;
      & > div {
        &:first-of-type {
          min-width: 56px;
        }
        &.not_expanded {
          min-width: 73px;
        }
      }
      img{
        margin-right: 20px;
      }
      p{
        color: #9B9B9B;
        font-family: "InterSemiBold",sans-serif;
        font-size: 11px;
        line-height: 14px;
        padding-bottom: 2px;
        text-transform: uppercase;
      }
      span{
        color: #000000;
        font-family: "InterSemiBold",sans-serif;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.step_wrapper {
  //overflow: hidden;
  position: relative;
  padding-top: 0;
  .head_block_step {
    width: 100%;
    border-bottom: none;
    padding: 0;
  }
}

.input_title_block_my_campaign{
  display: flex;
  flex-flow: column;
  padding: 20px 30px 30px 30px;
  & > div{
    display: flex;
    align-content: center;
    padding-bottom: 7px;
    & > img {
      cursor: help;
    }
    span{
      color: #383838;
      font-family: "InterSemiBold",sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin-right: 5px;
    }
  }
  input{
    height: 38px;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    border: 1px solid #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #383838;
    font-family: "InterRegular",sans-serif;
    font-size: 12px;
    line-height: 15px;
    &::placeholder{
      color: #9B9B9B;
      font-size: 12px;
    }
    &:focus{
      border-color:#111111 ;
    }
  }
}

.input_title_block_my_campaign_info{
  display: flex;
  flex-flow: column;
  padding: 0px 30px;
  div{
    display: flex;
    align-content: center;
    padding-bottom: 7px;
    & > img {
      cursor: help;
    }
    span{
      color: #383838;
      font-family: "InterSemiBold",sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin-right: 5px;
    }
  }
  input{
    height: 38px;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    border: 1px solid #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #383838;
    font-family: "InterRegular",sans-serif;
    font-size: 12px;
    line-height: 15px;
    &::placeholder{
      color: #9B9B9B;
      font-size: 12px;
    }
    &:focus{
      border-color:#111111 ;
    }
  }
}

.textarea_description_block_my_campaign{
  display: flex;
  flex-flow: column;
  padding:0px 30px 30px 30px;
  div{
    display: flex;
    align-content: center;
    padding-bottom: 7px;
    & > img {
      cursor: help;
    }
    span{
      color: #383838;
      font-family: "InterSemiBold",sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin-right: 5px;
    }
  }
  textarea{
    resize: none;
    height: 80px;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #383838;
    font-family: "InterRegular",sans-serif;
    font-size: 12px;
    line-height: 15px;
    &::placeholder{
      color: #9B9B9B;
      font-size: 12px;
    }
    &:focus{
      border-color:#111111 ;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height:5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: rgba(132, 148, 185,0.3);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(132, 148, 185,0.3);
    }
  }
}

.centered_loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete_dialog {
  width: 380px;
  display: flex;
  flex-flow: column;
  align-items: center;
  .sorry_word {
    color: #F23EA6;
    font-family: "InterSemiBold", sans-serif;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 39px;
    margin-bottom: 24px;
    text-align: center;
  }
  .dialog_title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 44px;
    &.text_centered {
      text-align: center;
    }
  }
  .buttons_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .edit_product_ok_btn {
    .ok_btn {
      width: 153px!important;
    }
  }
}

.campaign_preview_dialog {
  width: 380px;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: -20px;
  .dialog_title {
    font-family: "InterSemiBold", sans-serif;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #383838;
  }
}

.campaign_users_dialog {
  width: 450px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  .dialog_title {
    font-family: "InterSemiBold", sans-serif;
    font-size: 24px;
    color: #383838;
    margin: -30px 0 30px;
  }
  .description {
    font-size: 14px;
  }
  .users_list {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-height: 500px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 3.5px;
      background: rgba(56, 56, 56, 0.5);
      border-radius: 3.5px;
    }
    .user_row {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #E2E5EB;
      padding: 10px 40px 10px 0;
      .user {
        font-size: 14px;
        display: flex;
        align-items: center;
        .user_icon {
          margin-right: 10px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          height: 34px;
          width: 34px;
          border: 2px solid #FFFFFF;
          border-radius: 17px;
          background-color: #EEF3FF;
          font-size: 12px;
          font-family: "InterSemiBold", sans-serif;
          color: #383838;
          &.editor {
            background-color: #6429B3;
            color: #FFFFFF;
          }
        }
      }
      .user_role {
        color: #9B9B9B;
        font-size: 12px;
        text-transform: lowercase;
      }
      button {
        margin: 0!important;
      }
    }
  }
  .buttons_wrapper {
    padding-top: 48px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
  }
  .controls_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid #E2E5EB;
    & > div {
      font-size: 10px;
      font-family: "InterMedium", sans-serif;
      &:first-of-type {
        color: #F23EA6;
        margin-right: 24px;
      }
      &:last-of-type {
        color: #656565;
      }
    }
  }
  .links-wrapper {
    padding-top: 32px;
    font-size: 14px;
    line-height: 16px;
    & > a {
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
    }
  }
}

.step_input_wrapper {
  width: 100%;
  position: relative;
  img {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &.seconds_block {
    img {
      right: -20px;
    }
  }
  &.money_block {
    img {
      right: -20px;
    }
  }
}

.input_title_block_my_campaign{
  & > div {
    & > img {
      cursor: help;
    }
  }
}

.tooltip_name {
  font-family: "InterSemiBold", sans-serif;
  font-size: 12px;
  span {
    font-family: "InterLight", sans-serif;
    font-size: 10px;
  }
}

.input_title_campaign {
  display: flex;
  align-content: center;
  padding-bottom: 7px;
  & > img {
    cursor: help;
  }
  span{
    color: #383838;
    font-family: "InterSemiBold",sans-serif;
    font-size: 12px;
    line-height: 15px;
    margin-right: 5px;
  }
  p {
    color: #383838;
    font-size: 18px;
    letter-spacing: -0.2px;
    line-height: 21px;
    margin-right: 5px;
  }
}

.item_radio{
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
  &:first-child{
    margin-bottom: 10px;
  }
  & > div{
    display: flex;
    align-items: center;
    margin-right: 40px;
    input{
      display: none;
      & + label{
        position: relative;
        cursor: pointer;
        padding-left: 30px;
        &:before, &:after{
          content: "";
          position: absolute;
          border-radius: 50%;;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
        &:before{
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background: url("../assets/image/RadioOff.png") no-repeat;
        }
      }
      &:checked + label:before{
        background: url("../assets/image/RadioOn.png") no-repeat;

      }
    }
  }
  label{
    color: #383838;
    font-family: "InterRegular",sans-serif;
    font-size: 12px;
    line-height: 20px;
  }
}

.item_checkbox {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  position: relative;
  &.mb-10 {
    margin-bottom: 10px;
  }
  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    input[type=checkbox] {
      -moz-appearance: initial!important;
      position: relative;
      cursor: pointer;
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 5px;
      -webkit-appearance: button;
      height: 0;
      width: 19px;
      border-radius: 2px;
    }
    input[type=checkbox]:checked:after {
      content: "";
      position: absolute;
      left: 0;
      top: -11px;
      background: url("../assets/image/checkbox_checked.svg") no-repeat;
      width: 19px;
      height: 19px;
    }
    input[type=checkbox]:after {
      content: "";
      position: absolute;
      left: -0px;
      top: -11px;
      background: url("../assets/image/checkbox_unchecked.svg") no-repeat;
      width: 19px;
      height: 19px;
    }
  }
  label{
    cursor: pointer;
    color: #383838;
    font-family: "InterSemiBold",sans-serif;
    font-size: 12px;
    line-height: 20px;
    margin-left: 12px;
  }
}

.cursor_not_allowed {
  cursor: not-allowed!important;
  input {
    cursor: not-allowed!important;
  }
}

.rectangle_placeholder {
  border-radius: 4px;
  background-color: #E9E9E9;
  animation-name: impulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes impulse {
  from {background-color: rgba(233, 233, 233, 1);}
  to {background-color: rgba(233, 233, 233, 0.3);}
}

.page_title {
  color: #383838;
  font-size: 24px;
  line-height: 29px;
  font-family: "InterSemiBold", sans-serif;
}

.default_dialog_wrapper {
  width: 380px;
  display: flex;
  flex-flow: column;
  align-items: center;
  .dialog_title {
    min-height: 39px;
    color: #383838;
    font-family: "InterSemiBold", sans-serif;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 39px;
    margin-bottom: 24px;
    text-align: center;
  }
  .dialog_description {
    color: #383838;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
  .buttons_wrapper {
    margin-top: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .dialog_form {
    width: 100%;
    margin-top: 48px;
    label {
      font-size: 12px;
      font-family: "InterSemiBold", sans-serif;
    }
    .buttons_wrapper {
      margin-top: 0;
    }
    textarea {
      height: 84px;
    }
  }
  .input_wrapper{
    margin-top: 32px;
    width: 100%;
    &.mt-0 {
      margin-top: 0;
    }
    input{
      box-sizing: border-box;
      height: 37px;
      width: 100%;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding: 0 15px;
      font-family:"InterRegular",sans-serif;
      color: #111111;
      transition: all 0.3s linear;
      &::placeholder{
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus{
        border-color:#111111 ;
      }
    }
    .label {
      margin-bottom: 13px;
      color: #383838;
      font-size: 12px;
      line-height: 15px;
      font-family: "InterSemiBold", sans-serif;
    }
  }
  .dialog-info-text {
    margin-top: 20px;
    color: #9B9B9B;
    font-family: "InterMedium", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 13px;
  }
}

.pickup_address_dialog {
  width: 480px;
  display: flex;
  flex-flow: column;
  align-items: center;
  h2 {
    font-family: "InterSemiBold", sans-serif;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 32px;
    width: 100%;
  }
  .buttons_wrapper {
    margin: 48px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .default_button_wrapper {
      &:first-of-type {
        margin-right: 20px;
      }
      button {
        min-width: 153px!important;
      }
    }
  }
  .tabs_wrapper {
    width: 100%;
    height: unset;
    margin: -16px 0 24px;
    border-bottom: 1px solid #E9E9E9;
    .tab_wrapper {
      & > span {
        font-size: 10px;
      }
    }
  }
  .select_existing_address_wrapper {
    width: 100%;
  }
  .pickup_addresses_wrapper {
    max-height: 343px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 3.5px;
      background: rgba(56, 56, 56, 0.5);
      border-radius: 3.5px;
    }
    .address {
      cursor: pointer;
      span {
        display: flex;
        align-items: center;
        line-height: 20px;
      }
    }
  }
}

.not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.get_invoice_form {
  margin-top: 60px!important;
  text-align: center;
  .custom_input_wrapper {
    width: 160px;
  }
  .custom_input_wrapper .custom_input.custom_input_error:before, .custom_input_wrapper .custom_input.custom_input_error:hover:before {
    display: none;
  }
}

.Toastify__toast--error {
  background: #E02020!important;
  padding: 6px 16px!important;
  font-size: 0.875rem!important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif!important;
  height: 48px!important;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)!important;
  border-radius: 4px!important;
}

.Toastify__toast-container {
  width: 450px!important;
}

.add_address {
  color: #F23EA6;
  font-family: "InterMedium", sans-serif;
  font-size: 10px;
  padding: 18.5px 0;
  border-bottom: 1px solid #E2E5EB;
  width: 100%;
  cursor: pointer;
  transition: all .2s linear;
  &:hover {
    opacity: .7;
  }
}

.pickup_addresses_wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  .address {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 15px);
    padding: 17.5px 15px 17.5px 0;
    border-bottom: 1px solid #E2E5EB;
    & > span {
      font-size: 14px;
      & > img {
        margin-right: 12px;
        user-select: none;
        width: 24px;
        height: 24px;
      }
    }
    .address_controls {
      button {
        margin-left: 10px;
      }
    }
  }
}

.google_pickup_wrapper {
  width: 100%;
  & > span {
    display: block;
    font-size: 12px;
    margin-bottom: 12px;
    line-height: 15px;
    font-family: "InterSemiBold", sans-serif;
  }
  .pickup_map_wrapper{
    box-sizing: border-box;
    height: 226px;
    width: 100%;
    overflow: hidden;
    >div{
      position: relative!important;
      height: 100%!important;
      width: 100%!important;
      background: #CECECE;
    }
  }
  input{
    height: 38px;
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    border: 1px solid #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #383838;
    font-family: "InterRegular",sans-serif;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 24px;
    &::placeholder{
      color: #9B9B9B;
      font-size: 12px;
    }
    &:focus{
      border-color:#111111 ;
    }
  }
}

.tracking_info_dialog {
  padding: 40px!important;
  width: 440px;
  .tracking_info {
    width: 100%;
    display: flex;
    flex-flow: column;
    .dialog_title {
      font-size: 24px;
      font-family: "InterSemiBold", sans-serif;
      margin-bottom: 32px;
    }
    .input_title_block_my_campaign {
      margin-bottom: 24px;
      padding: 0!important;
      input:disabled {
        opacity: 0.7;
      }
    }
    .inputs_in_the_row {
      display: flex;
      align-items: center;
      width: 100%;
      .input_title_block_my_campaign {
        &:first-of-type {
          width: 100%;
          margin-right: 24px;
        }
      }
    }
    .pickup_address {
      font-size: 12px;
    }
    .buttons_wrapper {
      margin-top: 24px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .default_button_wrapper {
        &:first-of-type {
          margin-right: 20px;
          .auth {
            text-transform: uppercase!important;
            min-width: 153px!important;
          }
          .cancel_btn {
            text-transform: uppercase!important;
          }
        }
      }
    }
  }
}

.tooltip-question-icon {
  margin-left: 4px;
  cursor: pointer;
}

.items-center {
  display: flex;
  align-items: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.default-input {
  padding: 0 16px;
  box-sizing: border-box;
  border: 1px solid #CECECE;
  border-radius: 4px;
  background-color: #FFFFFF;
  font-family:"InterRegular",sans-serif;
  color: #111111;
  transition: all 0.3s linear;
  &::placeholder{
    color: #9B9B9B;
    font-size: 12px;
  }
  &:focus{
    border-color: #111111;
  }
}

.input-with-adornment {
  position: relative;
  .adornment {
    height: 33px;
    width: 39px;
    border-radius: 0 4px 4px 0;
    background-color: #F2F2F2;
    position: absolute;
    right: 16px;
    font-size: 12px;
    top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mb-20 {
  margin-bottom: 20px!important;
}

.mb-15 {
  margin-bottom: 15px!important;
}

.mb-40 {
  margin-bottom: 40px!important;
}

.mr-auto {
  margin-right: auto!important;
}

.mt-48 {
  margin-top: 48px!important;
}

.mt-24 {
  margin-top: 24px!important;
}

.mt-32 {
  margin-top: 32px!important;
}

#cf-root {
  &.cookiefirst-root {
    [data-cookiefirst-widget="banner"] {
      color: #6f2dbd !important;
      background-color: #f4f4f5 !important;
    }

    [data-cookiefirst-button="primary"],
    [data-cookiefirst-button="secondary"] {
      min-height: 45px !important;
      font-size: 16px !important;
      color: #fff !important;
      background-color: #f23ea6 !important;
      border-color: #f23ea6 !important;
      border-width: 2px !important;
      border-radius: 23px !important;

      span {
        font-family: "InterSemiBold", sans-serif !important;
      }

      &:hover {
        color: #6f2dbd !important;
        background-color: #fff !important;
        border-color: #6f2dbd !important;
        border-radius: 23px !important;
      }
    }

    .cf1Rhi {
      font-size: 16px !important;
    }

    p {
      color: #111 !important;
    }
  }
}

.no-padding-bottom {
  padding-bottom: 0;
}

.campy-coin {
  &__16 {
    margin-right: 6px;
    height: 16px;
    width: 16px;
  }
  &__22 {
    margin-right: 6px;
    height: 22px;
    width: 22px;
  }
}

.lowercase {
  text-transform: lowercase;
}

.input_wrapper--check-field {
  margin-top: 2px;
  display: flex;
  align-items: center;
  color: #383838;
  font-size: 12px;
  line-height: 16px;
}

.total-block {
  min-height: 47px;
  padding: 16px;
  border-radius: 8px;
  width: calc(100% - 32px);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  &__total {
    font-family: "InterSemiBold", sans-serif;
    font-size: 16px;
    letter-spacing: -0.18px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    &--icon {
      margin-right: 12px;
      width: 20px;
      min-width: 20px;
    }
  }
  &__items {
    font-size: 12px;
    letter-spacing: -0.1px;
    opacity: 0.75;
    text-align: center;
    &--item {
      margin: 0 8px;
    }
  }
  &__clickable {
    cursor: pointer;
    transition: all 0.2s linear;
    &:hover {
      opacity: 0.7;
    }
  }
}
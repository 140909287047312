.fields-wrapper {
  width: 100%;

  &.two-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
}

.country-fields {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 30px;
}

.country-select-label {
  display: flex;
  align-items: center;
  column-gap: 10px;

  img {
    border: 1px solid #E9E9EA;
    width: 16px;
    min-width: 16px;
  }
}
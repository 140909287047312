.new_qr_wrapper{
  position: absolute;
  top: -17px;
  left: -17px;
  z-index: 1;
  .qr_btn_wrapper{
    padding: 0 30px;
    box-sizing: border-box;
    .default_button_wrapper{
      .default_button{
        height: 30px;
        width: 190px;
        min-width: 190px;
        font-size: 11px;
        margin: 0!important;

      }
    }
  }
  .qr_code_info{
    margin-bottom: 30px;
    padding: 0 30px;
    display: flex;
    >div{
      margin-right: 30px;
      padding: 0;
      img {
        cursor: help;
      }
    }
    >div:first-of-type{
      .price_block_input{
        width: 150px;
        input{
          width: 150px;
        }
        >span{
          width: 65px;
        }
      }
    }
    >div:nth-child(2){
      .price_block_input{
        width: 124px;
        input{
          width: 124px;
        }
        >span{
          width: 62px;
        }
      }
    }
    .info{
      margin: 0;
      display: flex;
      flex-flow: column;
      p{
        color: #383838;
        font-family: "InterSemiBold",sans-serif;
        font-size: 12px;
        line-height: 15px;
        padding-bottom: 7px;
      }
      span{
        color: #383838;
        font-family: "InterRegular",sans-serif;
        font-size: 16px;
        line-height: 20px;
        height: 38px;
        display: flex;
        align-items: center;
      }
    }
  }
}
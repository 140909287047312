.add_product_wrapper{
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5% 0;
  background: rgba(242, 242, 242, 0.6);
  >div{
    min-width: 600px;
    height: max-content;
    background-color: #FFFFFF;
    padding: 70px 105px 60px;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
  }
  .title{
    color: #383838;
    font-family: "InterSemiBold",sans-serif;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 8px;
  }
  .info{
    color: #383838;
    font-size: 18px;
    margin: 40px 0 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    .tooltip-question-icon {
      width: 16px;
      height: 16px;
    }
  }
  .input_wrapper{
    &.product-id {
      width: 160px;
      margin: 0 auto;
      input {
        width: 100%;
      }
    }
    input{
      width: 410px;
      box-sizing: border-box;
      height: 48px;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding: 0 15px;
      font-family:"InterRegular",sans-serif;
      color: #111111;
      transition: all 0.3s linear;
      &::placeholder{
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus{
        border-color:#111111 ;
      }
    }
    &.full-width {
      input {
        width: 550px;
      }
    }
    p{
      padding-top: 6px;
      padding-bottom: 40px;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 15px;
    }
    textarea{
      resize: none;
      height: 136px;
      width: 550px;
      padding: 15px;
      box-sizing: border-box;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      color: #383838;
      font-family: "InterRegular",sans-serif;
      font-size: 12px;
      line-height: 15px;
      &::placeholder{
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus{
        border-color:#111111 ;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height:5px;
      }
      &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: rgba(132, 148, 185,0.3);
      }
      &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(132, 148, 185,0.3);
      }
    }
  }
  .price_block_input {
    position: relative;
    width: 170px;
    input {
      height: 48px;
      width: 169px;
      padding: 0 45px 0 15px;
      box-sizing: border-box;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      color: #383838;
      font-family: "InterRegular", sans-serif;
      font-size: 12px;
      line-height: 15px;
      &::placeholder {
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus {
        border-color: #111111;
      }
    }
    span{
      height: 46px;
      width: 38px;
      border-radius: 0 4px 4px 0;
      background-color: #F2F2F2;
      position: absolute;
      right: 2px;
      font-size: 16px;
      top: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .default_button_wrapper {
    margin-bottom: 60px;
    .default_button.auth{
      height: 40px;
      width: 153px;
      min-width: 153px;
      font-size: 14px;
    }
  }
  .upload_block{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    height: 119px;
    width: 100%;
    border: 2px dashed #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    cursor: pointer;
    img{
      margin-bottom: 22px;
    }
    span{
      color: #9B9B9B;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .drag_img{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 550px;
    & > div {
      display: flex;
      align-items: center;
      column-gap: 15px;
      margin-bottom: 12px;
      >div{
        color: #383838;
        font-size: 12px;
        line-height: 15px;
        margin-right: 15px;
      }
      & > img {
        width: 39px;
        height: 39px;
        border: 1px solid #E9E9E9;
        border-radius: 4px;
        object-fit: cover;
      }
    }
    >span{
      height: 100%;
      display: flex;
      align-items: center;
    }
    >button{
      border-radius: 4px;
      background: transparent;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .container {
    margin-top: 16px;
    width: 550px;
    .drop-container {
      box-sizing: border-box;
    }
  }
  .back{
    color: #F23EA6;
    font-family: "InterBold",sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
  }
  .fields-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .field-wrapper {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
  }
  .btn-wrapper {
    margin-top: 48px;
  }
}
.tutorial-link {
  display: flex;
  align-items: center;
  color: #F23EA6;
  font-family: "InterSemiBold", sans-serif;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  width: max-content;
  transition: 0.2s linear all;
  &.with-margin-top {
    margin-top: 24px;
  }
  &.with-margin-bottom {
    margin-bottom: 24px;
  }
  &.with-vertical-margin {
    margin: 24px 0;
  }
  &:hover {
    opacity: 0.7;
  }
  &__icon {
    margin-right: 12px;
    height: 34px;
    width: 34px;
  }
}
.breadcrumbs-tabs {
  display: flex;
  align-items: center;
  height: 100%;

  &__tab {
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: #9B9B9B;
    font-size: 14px;
    font-family: "InterSemiBold", sans-serif;
    position: relative;
    height: 100%;
    cursor: pointer;
    padding-right: 24px;
    margin-right: 16px;
    transition: 0.3s linear all;

    &.active-tab {
      color: #F23EA6;
    }

    &.with-error {
      position: relative;
      color: #E02020!important;

      &:before {
        position: absolute;
        content: "";
        top: calc(50% - 15px);
        left: 10px;
        height: 16px;
        width: 16px;
        background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Ewarning%3C/title%3E%3Cg id='Admin' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Campaigns---cost---validation' transform='translate(-402.000000, -72.000000)'%3E%3Cg id='actionbar' transform='translate(0.000000, 60.000000)'%3E%3Cg id='nav' transform='translate(390.000000, 12.000000)'%3E%3Cg id='warning' transform='translate(12.000000, 0.000000)'%3E%3Ccircle id='Oval' fill='%23FFFFFF' cx='8' cy='8' r='8'%3E%3C/circle%3E%3Cpath d='M8,0 C3.6,0 0,3.6 0,8 C0,12.4 3.6,16 8,16 C12.4,16 16,12.4 16,8 C16,3.6 12.4,0 8,0 Z M8,14 C4.7,14 2,11.3 2,8 C2,4.7 4.7,2 8,2 C11.3,2 14,4.7 14,8 C14,11.3 11.3,14 8,14 Z' id='Shape' fill='%23E02020' fill-rule='nonzero'%3E%3C/path%3E%3Crect id='Rectangle' fill='%23E02020' fill-rule='nonzero' x='7' y='4' width='2' height='5'%3E%3C/rect%3E%3Ccircle id='Oval' fill='%23E02020' fill-rule='nonzero' cx='8' cy='11' r='1'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
      }
    }

    &:after {
      content: "";
      display: block;
      background: url("../../../../assets/image/breadcrumb-tab-arrow.svg") no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      width: 15px;
      height: 100%;
    }

    &:last-of-type {
      margin-right: 0;
      &:after {
        display: none;
      }
    }
  }
}
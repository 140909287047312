.campaign_preview_wrapper {
  margin: 50px auto;
  position: relative;
  min-height: 605px;
  display: flex;
  &:before {
    position: absolute;
    background: url("../../../assets/image/mobile_frame.svg") no-repeat;
    content: '';
    right: calc(50% - 163px);
    height: 651px;
    width: 326px;
    transform: scale(1.1);
  }
  .mobile_header {
    z-index: 1;
    width: 160px;
    height: 28px;
    position: absolute;
    background-color: #f4f4f5;
    border-radius: 0 0 20px 20px;
    top: -3px;
    left: calc(50% - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      width: 38px;
      margin-bottom: 4px;
      margin-left: 20px;
      height: 6px;
      border-radius: 5px;
      background-color: #d5d5d5;
      margin-right: 10px;
    }
    span {
      margin-bottom: 5px;
      height: 6px;
      width: 6px;
      border: 1px solid #d5d5d5;
      border-radius: 50%;
      background-color: #f0f0f0;
    }
  }
  .mobile_footer {
    width: 266px;
    height: 28px;
    position: absolute;
    background-color: #f4f4f5;
    bottom: -19px;
    border-radius: 259px;
    left: 16px;
  }
  .mobile_view {
    background-color: #f4f4f5;
    border-radius: 9%;
    z-index: 1;
    width: 308px;
    display: flex;
    padding: 4px 7px 4px 4px;
    flex-flow: column;
    max-height: 630px;
    overflow: auto;
    position: relative;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      -webkit-border-radius: 2px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 3.5px;
      background: rgba(56, 56, 56, 0.5);
      border-radius: 3.5px;
    }
    .campaign_icon {
      border-radius: 9% 9% 0 0;
      max-height: 300px;
    }
    .preview_title {
      margin: 25px 0 15px;
      p {
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 16px;
        color: rgb(119, 119, 119);
      }
      div {
        line-height: 32px;
        font-family: "InterBold", sans-serif;
        font-size: 26px;
      }
    }
    .friends_wrapper {
      margin: 20px 0 30px;
      span {
        color: black;
        opacity: 0.3;
        font-family: "InterMedium", sans-serif;
        font-size: 9px;
        text-transform: uppercase;
      }
      .icons_in_row {
        margin-top: 5px;
        display: flex;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 5px;
        }
        div {
          width: 50px;
          height: 50px;
          font-family: "InterBold", sans-serif;
          font-size: 14px;
          border-radius: 50%;
          background-color: #75dbcd;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    h3 {
      font-family: "InterBold", sans-serif;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 15px;
    }
    .campaign_description {
      font-family: "InterBold", sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: rgb(51, 51, 51);
    }
    .company_icon {
      height: 156px;
    }
    .company_block {
      display: flex;
      flex-flow: column;
      height: 60px;
      background: white;
      padding: 10px;
      border-radius: 0 0 6px 6px;
      div {
        font-family: "InterBold", sans-serif;
        font-size: 16px;
        line-height: 22px;
      }
      p {
        font-size: 12px;
        line-height: 16px;
        color: rgb(119, 119, 119);
        text-transform: lowercase;
      }
    }
    .steps_wrapper {
      margin-bottom: 26px;
      .step_block {
        position: relative;
        display: flex;
        align-items: center;
        background: transparent;
        padding: 10px;
        border: 1px solid #CECECE;
        border-radius: 6px;
        margin-bottom: 14px;
        &.rewards {
          border: 1px solid #F23EA6;
        }
        &:before {
          content: "";
          position: absolute;
          bottom: -14px;
          width: 1px;
          height: 14px;
          left: 24px;
          background-color: lightgray;
        }
        &:last-of-type {
          &:before {
            display: none;
          }
        }
        p {
          margin-left: 10px;
          font-family: "InterBold", sans-serif;
          font-size: 12px;
          color: #111111;
        }
      }
    }
    .icons-on-top {
      position: absolute;
      right: 14px;
      top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 284px;
      & > div {
        display: flex;
        column-gap: 12px;
      }
    }
  }
  .step_number {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #111111;
    color: #FFFFFF;
    font-family: "InterBold", sans-serif;
    font-size: 14px;
    border-radius: 6px;
    &.reward {
      img {
        height: 17px;
      }
      background-color: #f23ea6;
    }
  }
}

.campaign-preview {
  &__statistics {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 50px;
    justify-content: space-between;
    column-gap: 15px;
    margin-bottom: 32px;
    & > div {
      display: flex;
      flex-flow: column;
    }
    span {
      font-size: 9px;
      line-height: 11px;
      text-transform: uppercase;
      color: #111111;
      opacity: 0.3;
      font-family: "InterMedium", sans-serif;
    }
    p {
      margin-top: 5px;
      font-size: 12px;
      line-height: 16px;
      color: rgb(119, 119, 119);
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
    }
  }
  &__product {
    &--icon {
      margin: 19px 0 11px;
      border-radius: 6px;
    }
    &--description {
      font-size: 14px;
      line-height: 20px;
      a {
        color: #F23EA6;
        font-family: "InterSemiBold", sans-serif;
        font-size: 14px;
        line-height: 20px;
      }
    }
    &--info {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &--label {
      font-family: "InterMedium", sans-serif;
      font-size: 9px;
      line-height: 11px;
      text-transform: uppercase;
      color: #111111;
      opacity: 0.3;
      margin-bottom: 5px;
    }
    &--price {
      display: inline-flex;
      align-items: center;
      p {
        font-family: "InterBold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #111111;
      }
      span {
        color: #888888;
        text-decoration: line-through;
      }
    }
    &--color {
      font-size: 16px;
      line-height: 22px;
      display: inline-flex;
      align-items: center;
      div {
        margin-right: 8px;
        width: 10px;
        min-width: 10px;
        height: 10px;
        min-height: 10px;
        border-radius: 50%;
      }
    }
  }
  &__gift {
    margin-top: 40px;
    &--wrapper {
      margin-top: 20px;
      padding: 10px;
      background: #E6E6E6;
      border-radius: 6px;
      display: grid;
      grid-template-columns: 100px 1fr;
      column-gap: 15px;
    }
    &--icon {
      border-radius: 5px;
      width: 100px;
      height: 80px;
      object-fit: cover;
    }
    &--name {
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px;
      color: #111111;
    }
    &--price {
      display: inline-flex;
      align-items: center;
      p {
        font-family: "InterBold", sans-serif;
        font-size: 14px;
        line-height: 22px;
        color: #111111;
      }
      span {
        font-size: 14px;
        line-height: 22px;
        color: #888888;
        text-decoration: line-through;
      }
    }
  }
  &__swipe-to-start {
    position: relative;
    margin: 40px 0 10px;
    &--text {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 28px;
      width: max-content;
      text-align: center;
      letter-spacing: 0.4px;
      color: #FFFFFF;
    }
    img {
      width: 100%;
    }
  }
  &__company-description {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    a {
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
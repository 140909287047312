.test_your_api_wrapper {
  margin: 50px 0 5px;
  width: 100%;
  overflow: auto;
  max-width: 100%;
  input {
    box-sizing: border-box;
    height: 37px;
    border: 1px solid #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 0 15px;
    font-family: "InterRegular",sans-serif;
    font-size: 14px;
    color: #111111;
    transition: all 0.3s linear;
    &::placeholder{
      color: #9B9B9B;
      font-size: 12px;
    }
    &:focus{
      border-color:#111111 ;
    }
  }
  p {
    color: #383838;
    font-size: 16px;
    line-height: 24px;
    overflow-wrap: anywhere;
  }
  .test_url {
    margin: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-family: "InterSemiBold", sans-serif;
    input {
      width: 140px;
    }
    .entered_url {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .item_radio {
    margin-top: 36px;
    .option_wrapper {
      & > div {
        display: flex;
        align-items: center;
      }
      label {
        font-size: 14px;
        font-family: "InterMedium", sans-serif;
        line-height: 18px;
      }
    }
  }
  .input-with-button {
    margin: 25px 0;
    display: flex;
    align-items: center;
    column-gap: 30px;
    input {
      width: 50%;
    }
  }
  .response_wrapper {
    font-size: 16px;
    border-radius: 8px;
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
    strong {
      font-weight: normal;
      font-family: "InterSemiBold", sans-serif;
    }
    &.success {
      background-color: #DEFFEE;
      p {
        color: #30837B;
      }
    }
    &.fail {
      background-color: rgba(224,32,32,0.1);
      p {
        color: #E02020;
      }
    }
  }
}
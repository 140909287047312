.calendar{
  padding: 0!important;
  div{
    padding-right:0!important;
  }
  .Mui-disabled {
    cursor: not-allowed!important;
  }
  >div{
    margin: 0;
    >div{
      position: relative;

      &:before{
        display: none;
      }
      &:after{
        display: none;
      }
      >div{
        position: absolute;
        right: -4px;
        width: 16px;
        height: 16px;
        top: calc(50% - 8px);
        >button{
          padding: 0;
          width: 16px;
          height: 16px;
          right: 16px;
          background: transparent!important;
          &:before{
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background: url("../../../assets/image/calendar-2.svg");
            width: 16px;
            height: 16px;
          }
          span{
            display: none;
          }
        }
      }
      input{
        padding: 0 30px 0 15px!important;
        height: 38px;
        width: 134px !important;
        box-sizing: border-box;
        font-family: "InterRegular",sans-serif!important;
        &::placeholder {
          opacity: 1!important;
        }
      }
    }
  }
}

.MuiPickersModal-dialogRoot {
  .MuiPickersDatePickerRoot-toolbar {
    background-color: #F23EA6!important;
  }

  .MuiPickersDay-daySelected {
    background-color: #F23EA6!important;
  }

  .MuiButton-textPrimary {
    color: #F23EA6!important;
  }
}
.MuiFormHelperText-root.Mui-error{
  display: none;
  position: absolute;
  bottom: -20px;
  color: #D0021B!important;
  font-size: 10px;
  margin: 0;
}
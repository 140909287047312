.cost_wrapper {
  display: flex;
  width: 100vw;
  .cost_container {
    display: flex;
    flex-flow: column;
    width: calc(100% - 504px);
    padding: 50px 73px 200px;
    background-color: #F8F8F8;
    background-size: 20px 20px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
}

.cost_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;
  .title {
    font-family: "InterSemiBold", sans-serif;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #383838;
  }
  .default_button {
    height: 41px!important;
    margin-left: 16px;
  }
  .cost_btn_wrapper {
    display: flex;
  }
}

.cost_block {
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px -2px rgba(88,88,88,0.3), 0 3px 13px 2px rgba(89,89,89,0.05);
  display: flex;
  flex-flow: column;
  margin-bottom: 32px;
  .cost_block_title {
    color: #656565;
    font-family: "InterSemiBold", sans-serif;
    font-size: 12px;
    line-height: 15px;
    margin: 24px 32px 0;
    text-transform: uppercase;
  }
  .inactive {
    color: #8E8E8E!important;
  }
  .cost_marked_block {
    border-radius: 8px 8px 0 0;
    background-color: rgba(242, 62, 166, 0.05);
    width: calc(100% - 64px);
    padding: 24px 32px;
    display: flex;
    & > div {
      width: 33%;
      font-family: "InterSemiBold", sans-serif;
      & > p {
        font-size: 14px;
        display: block;
        margin-bottom: 7px;
        line-height: 17px;
        color: #000000;
      }
      div {
        font-size: 20px;
        line-height: 24px;
        color: #383838;
        &.used {
          color: #8E8E8E;
          font-size: 12px;
          margin-top: 4px;
          line-height: 15px;
        }
        &.value_with_button {
          display: flex;
          .default_button_wrapper {
            margin-left: 64px;
            .default_button {
              margin: 0!important;
            }
          }
        }
      }
    }
  }
  .cost_block_info {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding: 48px 32px;
    .totals {
      display: flex;
      align-items: center;
      & > div {
        width: calc(33% - 32px);
        &:nth-of-type(2) {
          padding-left: 32px;
          width: calc(34% - 32px);
        }
      }
    }
    .total_estimated_cost {
      font-family: "InterSemiBold", sans-serif;
      margin-bottom: 48px;
      & > p {
        font-size: 14px;
        display: block;
        margin-bottom: 7px;
        line-height: 17px;
        color: #000000;
      }
      & > div {
        font-size: 48px;
        line-height: 59px;
        color: #383838;
      }
    }
    .split_info {
      width: 100%;
      display: flex;
      &.mb-32 {
        margin-bottom: 32px;
      }
      & > div {
        width: calc(33% - 32px);;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        font-family: "InterSemiBold", sans-serif;
        &:nth-of-type(2) {
          padding-left: 32px;
          width: calc(34% - 32px);
          border-left: 1px solid rgba(229, 109, 177, 0.3);
        }
        &:nth-of-type(3) {
          padding-left: 32px;
          width: 33%;
          border-left: 1px solid rgba(229, 109, 177, 0.3);
        }
        & > p {
          font-size: 14px;
          display: block;
          margin-bottom: 7px;
          line-height: 17px;
          color: #000000;
        }
        & > div {
          font-size: 20px;
          line-height: 24px;
          color: #8E8E8E;
          & > span {
            color: #6429B3;
          }
        }
      }
    }
  }
}

.sharing-wrapper {
  padding: 16px 32px 32px;
  .tabs_wrapper {
    width: 100%;
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: 32px;
  }
  .table_container {
    .table_header {
      user-select: none;
      .row_item {
        font-family: "InterRegular", sans-serif;
        font-size: 12px;
        color: #9B9B9B;
        height: 100%;
      }
    }
    .table_body {
      .table_row {
        min-height: 55px;
        height: max-content;
        width: 100%;
        display: flex;
      }
    }
    .fb_table_header,
    .fb_table_body {
      .table_row {
        width: 100%;
        .row_item {
          display: flex;
          align-items: center;
          width: 20%;
          &:last-child {
            justify-content: center;
          }
        }
      }
    }
    .link_table_header,
    .link_table_body {
      .table_row {
        width: 100%;
        .row_item {
          display: flex;
          align-items: center;
          width: 33%;
        }
      }
    }
  }
  .pagination-container {
    justify-content: center;
  }
  .used_at {
    color: #383838;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
  .reward {
    color: #069284;
    font-size: 14px;
    font-family: "InterSemiBold", sans-serif;
    letter-spacing: 0;
    line-height: 18px;
  }
  .status {
    padding: 7px 16px;
    border-radius: 16px;
    font-size: 14px;
    font-family: "InterSemiBold", sans-serif;
    letter-spacing: 0;
    line-height: 18px;
    &.joined {
      background-color: #EEF3FF;
      color: #324EA3;
    }
    &.started, &.finished {
      background-color: #F8F2FF;
      color: #6429B3;
    }
    &.pending {
      background-color: #FFF3E7;
      color: #FE8F1D;
    }
    &.paid {
      background-color: #DEFFEE;
      color: #069284;
    }
  }
  .post-must-be-available {
    font-size: 14px;
    line-height: 18px;
    color: #383838;
    & > span {
      font-family: "InterMedium", sans-serif;
    }
  }
  .open-post {
    color: #F23EA6;
    font-family: "InterMedium", sans-serif;
    font-size: 14px;
    & > img {
      margin-left: 6px;
    }
  }
}
.audience-page {
  &__page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5% 0;
    background: rgba(242, 242, 242, 0.6);
    .container {
      width: 100%;
      margin-bottom: 60px;
    }
    .drop-container {
      height: 136px;
    }
    &.align-start {
      align-items: flex-start;
      padding: 0;
    }
  }
  &__wrapper {
    width: 760px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    padding: 75px 105px 72px;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
    &.view-single {
      width: 100%;
      margin: 64px;
      padding: 40px 40px 50px;
    }
  }
  &__back {
    margin-right: auto;
    width: max-content;
    color: #F23EA6;
    font-size: 14px;
    font-family: "InterBold", sans-serif;
    letter-spacing: 1px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    & > img {
      margin-right: 10px;
    }
  }
  &__name {
    width: 100%;
    font-size: 24px;
    font-family: "InterSemiBold",sans-serif;
    line-height: 26px;
    margin: 50px 0 40px;
  }
  &__title {
    color: #383838;
    font-family: "InterSemiBold",sans-serif;
    font-size: 32px;
    line-height: 39px;
    padding-bottom: 47px;
  }
  &__label {
    color: #383838;
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 18px;
    &.edit-mode {
      font-family: "InterSemiBold",sans-serif;
      font-size: 12px;
      line-height: 15px;
      padding-bottom: 13px;
      width: 100%;
    }
  }
  &__input-wrapper {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    input, textarea {
      box-sizing: border-box;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      font-family:"InterRegular",sans-serif;
      color: #111111;
      transition: all 0.3s linear;
      &::placeholder{
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus{
        border-color:#111111;
      }
    }
    input {
      padding: 0 15px;
      height: 48px;
      width: 410px;
    }
    textarea {
      padding: 12px 15px;
      width: 100%;
      height: 136px;
      resize: none;
      &::-webkit-scrollbar {
        width: 5px;
        height:5px;
      }
      &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: rgba(132, 148, 185,0.3);
      }
      &::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(132, 148, 185,0.3);
      }
    }
    &.edit-mode {
      input {
        width: 100%;
        padding: 11px 15px;
        height: 37px;
      }
    }
  }
  &__file {
    color: #383838;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 15px;
    width: 100%;
    &-icon {
      margin-right: 8px;
      width: 12px;
    }
  }
  &__cancel {
    margin-top: 60px;
    color: #F23EA6;
    font-family: "InterBold",sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
  }
  &__submit-btn {
    width: 257px;
  }
  &__view-label {
    font-size: 12px;
    line-height: 13px;
    font-family: "InterSemiBold",sans-serif;
    margin-bottom: 8px;
    width: 100%;
  }
  &__view-value {
    font-size: 12px;
    line-height: 15px;
    width: 100%;
    margin-bottom: 30px;
  }
  &__edit-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
  }
  &__edit-btn {
    min-width: 153px!important;
  }
}

.add-new-product {
  &__dialog {
    margin-top: -20px;
    width: 400px;
    display: flex;
    flex-flow: column;
    align-items: center;
    .add_product_wrapper {
      background: transparent;
      padding: 0 0 40px;
      width: 100%;
      & > div {
        min-width: unset;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        background: transparent;
        width: 100%;
      }
      .title {
        font-size: 32px;
        letter-spacing: 0;
        line-height: 35px;
      }
      .info {
        margin: 20px 0 12px;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 13px;
        font-family: "InterSemiBold", sans-serif;
        justify-content: flex-start;
        width: 100%;
      }
      .input_wrapper {
        width: 100%;
        input, textarea {
          width: 100%;
        }
        input {
          height: 37px;
        }
        textarea {
          height: 84px;
        }
      }
      .drag_img {
        width: 100%;
      }
      .price_block_input {
        width: 144px;
        input {
          height: 37px;
          width: 100%;
        }
        span {
          height: 35px;
          width: 35px;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 13px;
        }
      }
      .color_picker_wrapper {
        .color_field {
          height: 21px!important;
          width: 201px!important;
          .color_preview {
            height: 21px!important;
            width: 40px!important;
          }
          p {
            font-size: 12px;
            letter-spacing: 0;
            line-height: 13px;
          }
        }
        .colors_container {
          width: 226px!important;
          .color_preview {
            height: 24px!important;
            width: 35px;
          }
        }
      }
      .container {
        width: 100%;
        .drop-container {
          height: 112px;
          width: 100%;
        }
      }
      .fields-wrapper {
        flex-wrap: wrap!important;
        width: 100%;
        flex-flow: row;
        justify-content: space-between;
        .field-wrapper {
          width: calc(50% - 20px);
          .color_picker_wrapper {
            width: 100%;
            .color_field {
              width: calc(100% - 23px)!important;
            }
            .current_color {
              width: 39px!important;
              height: 28px!important;
            }
          }
          .price_block_input {
            width: 100%;
          }
          &:last-of-type {
            width: 100%;
          }
        }
      }
      .btn-wrapper {
        justify-content: center;
        p {
          height: 40px;
          width: 153px;
          border: 1px solid #F5F5F5;
          border-radius: 20px;
          background-color: #F5F5F5;
          color: #111111;
          font-family: "InterBold", sans-serif;
          font-size: 14px;
          letter-spacing: 1px;
          line-height: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .default_button_wrapper {
        margin: 0 20px 0 0;
        .default_button {
          min-width: 153px!important;
        }
      }
    }
  }
}

.select-with-product {
  margin-bottom: 0!important;
  .select__control {
    border-radius: 4px 4px 0 0!important;
  }
}

.selected-product {
  margin-bottom: 20px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #CECECE;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #FFFFFF;
  &__icon {
    width: 50px;
    height: 50px;
    margin-right: 16px;
    border: 1px solid #E9E9E9;
    border-radius: 4px;
    overflow: hidden;
  }
  &__price {
    &--label {
      margin-bottom: 5px;
      font-size: 12px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 13px;
    }
    &--value {
      font-size: 16px;
      font-family: "InterBold", sans-serif;
      line-height: 17px;
    }
  }
}

.product-step-separator {
  margin: 30px 30px 14px;
  width: calc(100% - 60px);
  height: 0;
  background-color: transparent;
  border-top: 1px dashed #E2E5EB;
}

.sizes {
  width: 400px;
  display: flex;
  flex-flow: column;
  row-gap: 10px;
  &__title {
    font-size: 12px;
    font-family: "InterSemiBold", sans-serif;
    line-height: 13px;
  }
  &__size {
    display: flex;
    align-items: center;
  }
  &__input-field {
    height: 29px;
    width: 170px;
    border: 1px solid #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 12px;
    line-height: 13px;
    color: #383838;
    &::placeholder {
      color: #9B9B9B;
      font-size: 12px;
      line-height: 13px;
    }
  }
  &__quantity {
    &--wrapper {
      position: relative;
      .sizes__input-field {
        margin-left: 10px;
        padding: 0 70px 0 10px;
      }
    }
    &--label {
      position: absolute;
      right: 1px;
      top: 1px;
      height: 27px;
      width: 62px;
      background-color: #F2F2F2;
      border-radius: 0 4px 4px 0;
      color: #383838;
      font-size: 12px;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__del-btn {
    margin: 0 20px!important;
    width: 24px;
    height: 24px;
  }
}
.audience-list {
  &__title {
    color: #383838;
    font-size: 24px;
    line-height: 29px;
    font-family: "InterSemiBold", sans-serif;
    margin-bottom: 18px;
  }
  &__wrapper {
    display: flex;
    flex-flow: column;
    margin-top: 32px;
  }
  &__tabs {
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: 32px;
  }
  &__search {
    position: relative;
    margin-right: 20px;
    input {
      box-sizing: border-box;
      height: 37px;
      width: 288px;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding: 0 20px 0 39px;
      font-family:"InterRegular",sans-serif;
      color: #111111;
      transition: all 0.3s linear;
      @media (max-width: 1400px) {
        width: 200px;
      }
      &::placeholder {
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus {
        border-color:#111111 ;
      }
    }
    img {
      position: absolute;
      width: 16px;
      height: 16px;
      left: 15px;
      top: calc(50% - 8px);
    }
  }
  &__select {
    width: 204px;
    margin-right: 10px!important;
  }
  &__sort-by {
    margin-left: 10px;
    font-size: 12px;
    line-height: 15px;
    &--label {
      color: #9B9B9B;
      margin-right: 4px;
    }
    &--value {
      font-size: 12px;
      font-family: "InterSemiBold", sans-serif;
      line-height: 15px;
      margin-right: 10px;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
    padding: 30px 28px 32px 40px;
    position: relative;
    min-width: 898px;
    &--status {
      position: absolute;
      left: 40px;
      top: 0;
      padding: 2px 10px;
      font-family: "InterMedium", sans-serif;
      font-size: 10px;
      line-height: 12px;
      border-radius: 0 0 2px 2px;
      text-transform: uppercase;
      &.available {
        color: #239B5F;
        background-color: rgba(35, 155, 95, 0.1);
      }
      &.in_use {
        color: #6429B3;
        background-color: rgba(100, 41, 179, 0.1);
      }
      &.archived {
        color: #FFFFFF;
        background-color: #CECECE;
      }
    }
    &--main-info {
      display: flex;
      flex-flow: column;
      max-width: 40%;
      &.max-width-60 {
        max-width: 60%;
      }
      &-name {
        color: #383838;
        font-family: "InterSemiBold",sans-serif;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
        cursor: pointer;
      }
      &-description {
        font-size: 14px;
        line-height: 17px;
        color: #9B9B9B;
        & > span {
          margin: 0 3px;
          color: #656565;
        }
      }
    }
    &--support-info {
      display: flex;
      flex-flow: column;
      margin-right: 120px;
      &.smaller-mr {
        margin-right: 60px;
      }
      &.min-width-260 {
        min-width: 260px;
        margin-right: 20px;
      }
      &-title {
        color: #9B9B9B;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;
      }
      &-value {
        color: #383838;
        font-family: "InterSemiBold",sans-serif;
        font-size: 20px;
        line-height: 20px;
      }
      &-more {
        position: relative;
        width: max-content;
        &-icon {
          width: 45px;
          height: 45px;
        }
      }
    }
    &--match-rate {
      display: flex;
      align-items: flex-end;
      &-value {
        color: #069284;
        font-family: "InterSemiBold",sans-serif;
        font-size: 20px;
        line-height: 20px;
        margin-right: 8px;
      }
      &-text {
        color: #9B9B9B;
        font-size: 14px;
        line-height: 16px;
        margin-right: 3px;
      }
      &-ago {
        color: #656565;
        font-size: 14px;
        line-height: 16px;
      }
    }
    &--actions {
      &-menu {
        z-index: 1000;
        right: 0;
        top: 50px;
        padding: 10px 0;
        position: absolute;
        box-sizing: border-box;
        height: max-content;
        width: 240px;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
        color: #000000;
      }
      &-action {
        box-sizing: border-box;
        width: 100%;
        padding: 10px 48px 10px 16px;
        text-align: left;
        font-size: 14px;
        font-family: "InterRegular", sans-serif;
        display: flex;
        align-items: center;
        color: #383838;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
      &-icon {
        margin-right: 8px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &--duplicate-name {
      width: 100%;
      margin-bottom: 48px;
      .label {
        font-size: 12px;
        font-family: "InterSemiBold", sans-serif;
        line-height: 15px;
        margin-bottom: 13px;
        width: 100%;
        text-align: left;
      }
      .default-input {
        width: 100%;
        height: 37px;
      }
    }
  }
}
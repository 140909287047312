.language-switcher {
  position: relative;
  width: 71px;
  &__option {
    color: #111111;
    font-family: "InterRegular", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    transition: 0.3s linear all;
    &.in-white {
      color: #FFFFFF;
    }
    .in-bold {
      font-family: "InterSemiBold", sans-serif;
    }
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  &__flag {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  &__flag {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  &__arrow {
    width: 12px;
    margin-left: 8px;
    transition: 0.2s linear all;
  }
  &__checkmark {
    margin-left: 8px;
  }
  &__options-popup {
    z-index: 1000;
    position: absolute;
    left: -18px;
    top: 32px;
    display: flex;
    flex-flow: column;
    row-gap: 16px;
    border: 1px solid #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.2);
    padding: 16px;
  }
}

.editable-text {
  display: flex;
  align-items: center;

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    margin-left: 10px;
  }

  &__input {

    box-sizing: border-box;
    border: 1px solid #CECECE;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #383838;
    font-family: "InterRegular",sans-serif;

    &::placeholder {
      color: #9B9B9B;
    }

    &:focus {
      border-color: #111111;
    }
  }
}
.users_title_block{
  display: flex;
  justify-content: space-between;
  .title{
    color: #383838;
    font-size: 24px;
    line-height: 29px;
    font-family: "InterSemiBold", sans-serif;
  }
}

.users_block {
  display: flex;
  flex-flow: column;
  .users_tabs_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
    border-bottom: 1px solid #E9E9E9;
    .tabs_wrapper {
      width: 100%;
      margin-bottom: -1px;
    }
  }
  .title_panel{
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    height: 38px;
    .search{
      position: relative;
      margin-right: 20px;
      input{
        box-sizing: border-box;
        height: 37px;
        width: 288px;
        border: 1px solid #CECECE;
        border-radius: 4px;
        background-color: #FFFFFF;
        padding: 0 20px 0 39px;
        font-family:"InterRegular",sans-serif;
        color: #111111;
        transition: all 0.3s linear;
        &::placeholder{
          color: #9B9B9B;
          font-size: 12px;
        }
        &:focus{
          border-color:#111111 ;
        }
      }
      button{
        cursor: auto;
        display: flex;
        align-items: center;
        background: transparent;
        justify-content: center;
        position: absolute;
        width: 16px;
        height: 16px;
        left: 15px;
        top: calc(50% - 8px);
        //img{
        //  transition: all 0.3s linear;
        //}
        //&:hover{
        //  img{
        //    transform: scale(1.1);
        //  }
        //}
      }
    }
    .select_wrapper{
      width: 145px;
      margin-right: 20px;
    }
  }
}

.users_content{
  display: flex;
  flex-flow: column;
  >div{
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
    display: flex;
    justify-content: space-between;
    .content{
      width: 60%;
      display: flex;
      align-items: center;
      &.invited {
        width: 80%;
        .user_info {
          .name {
            max-width: 800px;
          }
        }
      }
    }
    .img_block{
      margin-right: 30px;
      height: 111px;
      width: 133px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px 0 0 8px;
      img{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        max-width: 133px;
      }
    }
    .user_info{
      display: flex;
      flex-flow: column;
      margin-right: 115px;
      position: relative;
      width: calc(100% - 278px);
      .user-status {
        position: absolute;
        top: -31px;
        display: flex;
        align-items: center;
        column-gap: 8px;
        .active, .blocked, .owner {
          border-radius: 0 0 2px 2px;
          font-family: "InterMedium", sans-serif;
          font-size: 10px;
          line-height: 16px;
          padding: 0 10px;
          text-transform: uppercase;
        }
        .active {
          background: rgba(35, 155, 95, 0.1);
          color: #239B5F;
        }
        .blocked {
          background: #CECECE;
          color: #FFFFFF;
        }
        .owner {
          background: rgba(#6429B3, 0.1);
          color: #6429B3;
        }
      }
      .name {
        color: #383838;
        font-family: "InterSemiBold",sans-serif;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
        max-width: 600px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      >div{
        width: 100%;
      }
      .last_updated_info{
        font-size: 14px;
        line-height: 17px;
        color: #9B9B9B;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span{
          margin: 0 3px;
          color: #656565;
        }
      }
      .to_company {
        color: #6429B3;
        font-size: 14px;
        line-height: 17px;
      }
    }
    .data_info{
      display: flex;
      flex-flow: column;
      margin-right: 50px;
      span{
        color: #9B9B9B;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 14px;
      }
      p{
        color: #383838;
        font-family: "InterSemiBold",sans-serif;
        font-size: 20px;
      }
    }
    .more_info_btn{
      display: flex;
      align-items: center;
      margin-right: 31px;
      width: 40%;
      justify-content: space-between;
      &.invited {
        width: 20%;
      }
      .campaigns_wrapper {
        p {
          color: #9B9B9B;
          font-size: 14px;
          line-height: 17px;
        }
        div {
          margin-top: 7px;
          color: #F23EA6;
          font-size: 20px;
          font-family: "InterSemiBold", sans-serif;
          line-height: 24px;
        }
      }
      img{
        margin: 0 10px;
        cursor: pointer;
      }
    }
    .menu {
      z-index: 1000;
      right: 30px;
      top: 81px;
      padding: 10px 0;
      position: absolute;
      box-sizing: border-box;
      height: max-content;
      width: 220px;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
      color: #000000;
      button {
        width: 100%;
        padding: 10px 18px;
        text-align: left;
        font-size: 14px;
        font-family: "InterRegular", sans-serif;
        display: flex;
        align-items: center;
        &:hover {
          background-color: rgba(0,0,0,0.05);
        }
        img {
          width: 16px;
          margin-right: 12px;
          &.remove_icon {
            width: 10px;
            margin: 0 15px 0 3px;
          }
        }
      }
    }
  }
}

.users_pagination_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

//.users_content{
//  display: flex;
//  flex-flow: column;
//  background: #FFFFFF;
//  box-shadow: 0px 5px 23px rgba(89, 89, 89, 0.05), 0px 12px 19px rgba(92, 92, 92, 0.06), 0px 5px 7px rgba(88, 88, 88, 0.2);
//  border-radius: 8px;
//  padding: 40px;
//  .table_header,
//  .table_body {
//    .table_row {
//      width: calc(100% - 40px);
//      padding: 0 20px;
//      .row_item {
//        display: flex;
//        align-items: center;
//        &:first-child {
//          width: 30%;
//        }
//        &:nth-child(2) {
//          width: 60%;
//        }
//        &:last-child {
//          width: 10%;
//        }
//      }
//      &.margin_for_row {
//        margin-bottom: 24px;
//      }
//    }
//  }
//  .table_body {
//    .row_item {
//      &:first-child {
//        p {
//          font-size: 14px;
//          line-height: 17px;
//          color: #383838;
//        }
//        span {
//          font-size: 12px;
//          line-height: 15px;
//          color: #9B9B9B;
//        }
//      }
//      &:nth-child(2) {
//        font-size: 12px;
//        line-height: 15px;
//        color: #9B9B9B;
//      }
//      &:last-child {
//        display: flex;
//        justify-content: flex-end;
//      }
//    }
//  }
//}
.challenges_wrapper{
  .block_wrapper{
    border: 2px solid #324EA3;
  }
}

.video_wrapper {
  position: absolute;
  top: -17px;
  left: -17px;
  z-index: 1;
}

.youtube-url-warning {
  color: #9B9B9B;
  font-size: 12px;
  line-height: 13px;
}
.challenges_wrapper{
  .block_wrapper{
    border: 2px solid #324EA3;
  }
}

.check_text_wrapper {
  position: absolute;
  top: -17px;
  left: -17px;
  z-index: 1;
}
.audience_wrapper {
  display: flex;
  width: 100vw;
  .audience_container {
    display: flex;
    justify-content: center;
    width: calc(100% - 604px);
    padding: 30px 123px 80px;
    background-color: #F8F8F8;
    background-image: -webkit-radial-gradient(#d8d8d8 1px, transparent 0);
    background-image: radial-gradient(#d8d8d8 1px, transparent 0);
    background-size: 20px 20px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
}

.choose_your_audience {
  width: 100%;
  max-width: calc(835px - 100px);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 40px 67px 60px 100px;
  background: #FFFFFF;
  box-shadow: 0px 5px 7px -3px rgba(88, 88, 88, 0.2), 0px 12px 19px 1px rgba(92, 92, 92, 0.06), 0px 5px 23px 4px rgba(89, 89, 89, 0.05);
  border-radius: 8px;
  .title {
    font-family: "InterSemiBold", sans-serif;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #383838;
  }
  .description {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.177778px;
    color: #656565;
    margin: 24px 0 43px;
  }
  .potential_reach {
    background: #EEF3FF;
    border-radius: 8px;
    height: 62px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    p {
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.177778px;
      font-family: "InterSemiBold", sans-serif;
      color: #324EA3;
      img {
        margin-right: 18px;
      }
    }
    .default_button_wrapper {
      margin-left: 24px;
    }
  }
  .audience_row_wrapper {
    display: flex;
    min-height: 80px;
    width: 100%;
    margin: 10px 0;
    .row_name {
      font-size: 16px;
      font-family: "InterSemiBold", sans-serif;
      width: 110px;
      border-right: 2px solid #E9E9E9;
      margin-right: 40px;
      position: relative;
      img {
        position: absolute;
        top: 2px;
        left: -24px;
        cursor: help;
      }
      .small-text {
        margin-top: 2px;
        color: #9B9B9B;
        font-size: 10px;
        line-height: 15px;
        font-family: "InterRegular", sans-serif;
      }
    }
    .row_content {
      display: flex;
      flex-flow: column;
      width: calc(100% - 150px);
      margin-top: 7px;
      .age_wrapper {
        display: flex;
        & > div {
          margin-right: 20px;
          width: 150px;
          .select_wrapper {
            width: 100%;
          }
        }
      }
      .item_radio {
        flex-flow: column;
        align-items: flex-start;
        margin-bottom: 0;
        & > div {
          margin-bottom: 10px;
        }
      }
      .audience_limit {
        .select_wrapper {
          min-width: 435px;
        }
      }
      .minimum_customer_ranking {
        margin: 25px 0;
        .select_wrapper {
          min-width: 145px;
        }
      }
      .map_wrapper{
        margin-bottom: 10px;
        box-sizing: border-box;
        height: 226px;
        width: 100%;
        overflow: hidden;
        >div{
          position: relative;
          height: 100%;
          width: 100%;
          background: #CECECE;
        }
      }
      .address_info_map{
        display: flex;
        margin-bottom: 20px;
        .input_title_block_my_campaign{
          padding: 0;
          margin: 0;
          margin-right: 20px;
          width: 100%;
        }
        .price_block_mini{
          .title {
            img {
              &:hover {
                cursor: help;
              }
            }
          }
          .price_block_input{
            width: 156px;
            input{
              width: 156px;
            }
            span{
              width: 49px;
            }
          }
        }
      }
    }
  }
  .save_button {
    margin-top: 50px;
  }
}

.interests_wrapper {
  display: flex;
  flex-flow: wrap;
  width: 110%;
  .interest_item {
    margin: 0 10px 10px 0;
    padding: 5px 10px;
    border-radius: 12.5px;
    background-color: #F5F5F5;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    cursor: pointer;
    font-size: 12px;
    line-height: 15px;
    color: #656565;
    &:hover {
      background: rgba(242, 62, 166, 0.7);
      color: #FFFFFF;
    }
    &.no_hover {
      cursor: not-allowed;
      &:hover {
        background: #F5F5F5;
        color: #656565;
      }
    }
    &.chosen_one {
      background: rgba(242, 62, 166, 1);
      color: #FFFFFF;
      &.no_hover {
        cursor: not-allowed;
        &:hover {
          background: rgba(242, 62, 166, 1);
          color: #FFFFFF;
        }
      }
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  column-gap: 8px;
  border-radius: 15px;
  background-color: #4D2096;
  padding: 0 20px;

  &__arrow {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }

  &__parent, &__child {
    padding: 7px 0;
    font-size: 12px;
    font-family: "InterBold", sans-serif;
    line-height: 16px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__parent {
    color: #B9A5DC;
    cursor: pointer;
  }

  &__child {
    color: #FFFFFF;
  }
}
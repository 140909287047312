.add_products_via_api_container{
  display: flex;
  justify-content: center;
  padding: 64px;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  max-width: 100%;
  background: rgba(242, 242, 242, 0.6);
  .back{
    color: #F23EA6;
    font-family: "InterBold",sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    img {
      margin-right: 10px;
    }
  }
  >div{
    height: max-content;
    box-sizing: border-box;
    width: 100%;
    background-color: #FFFFFF;
    padding: 40px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border-radius: 8px;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
  }
  .title{
    font-family: "InterSemiBold",sans-serif;
    font-size: 24px;
    line-height: 29px;
    color: #383838;
  }
  .description {
    font-size: 16px;
    line-height: 24px;
    margin: 30px 0;
  }
  .separator {
    margin: 50px 0;
  }
  .btn_wrapper {
    margin-top: 50px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .select_wrapper {
    width: 100%;
    .select_component {
      width: 50%;
    }
  }
  .item_radio{
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    position: relative;
    width: 100%;
    margin-bottom: 0;
    .option_wrapper {
      width: 50%;
      &:first-of-type {
        margin-bottom: 17px;
      }
      &:nth-of-type(2) {
        margin: 24px 0;
      }
      &:last-of-type {
        margin: 17px 0;
      }
      & > div{
        display: flex;
        align-items: center;
        width: 210px;
        & > input{
          display: none;
          & + label{
            position: relative;
            cursor: pointer;
            font-family: "InterSemiBold",sans-serif;
            font-size: 16px;
            line-height: 24px;
            padding-left: 36px;
            &:before, &:after{
              content: "";
              position: absolute;
              border-radius: 50%;;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              transition: all 0.3s ease;
            }
            &:before{
              top: 2px;
              left: 0;
              height: 20px;
              width: 20px;
              background: url("../../../assets/image/RadioOff.png") no-repeat;
            }
          }
          &:checked + label:before{
            background: url("../../../assets/image/RadioOn.png") no-repeat;
          }
        }
      }
      & > input {
        display: flex;
        box-sizing: border-box;
        height: 37px;
        width: calc(100% - 210px);
        border: 1px solid #CECECE;
        border-radius: 4px;
        background-color: #FFFFFF;
        padding: 0 15px;
        font-family: "InterRegular",sans-serif;
        font-size: 14px;
        color: #111111;
        transition: all 0.3s linear;
        &::placeholder{
          color: #9B9B9B;
          font-size: 12px;
        }
        &:focus{
          border-color:#111111 ;
        }
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .response_wrapper {
    font-size: 16px;
    border-radius: 8px;
    padding: 30px;
    margin: 20px 0 50px;
    width: 100%;
    box-sizing: border-box;
    strong {
      font-weight: normal;
      font-family: "InterSemiBold", sans-serif;
    }
    &.success {
      background-color: #DEFFEE;
      .success_header {
        display: flex;
        justify-content: space-between;
        .show_response {
          font-family: "InterSemiBold", sans-serif;
          img {
            margin-left: 4px;
            transition: all 0.3s linear;
            &.reversed {
              transform: rotate(180deg);
            }
          }
        }
      }
      .collapsible_block {
        display: flex;
        flex-flow: column;
      }
      p {
        color: #30837B;
        line-height: 24px;
        font-family: "InterRegular", sans-serif;
        word-wrap: anywhere;
      }
    }
    &.fail {
      background-color: rgba(224,32,32,0.1);
      p {
        color: #E02020;
        line-height: 24px;
        font-family: "InterRegular", sans-serif;
      }
    }
  }
  .products_wrapper {
    width: 100%;

    .search-panel {
      margin: 24px 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .search {
      position: relative;
      margin-right: 20px;
      input {
        box-sizing: border-box;
        height: 37px;
        width: 288px;
        border: 1px solid #CECECE;
        border-radius: 4px;
        background-color: #FFFFFF;
        padding: 0 20px 0 39px;
        font-family:"InterRegular",sans-serif;
        color: #111111;
        transition: all 0.3s linear;

        &::placeholder {
          color: #9B9B9B;
          font-size: 12px;
        }

        &:focus {
          border-color:#111111 ;
        }
      }

      button {
        display: flex;
        align-items: center;
        background: transparent;
        justify-content: center;
        position: absolute;
        width: 16px;
        height: 16px;
        left: 15px;
        top: calc(50% - 8px);
      }
    }

    .products-selected {
      color: #9B9B9B;
      font-size: 14px;
      line-height: 18px;
    }

    .no-results {
      font-family: "InterMedium", sans-serif;
      margin-top: 16px;
      font-size: 14px;
      line-height: 18px;
    }

    .warning-icon {
      height: 16px;
      width: 16px;
      margin-left: 6px;
    }

    .product {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 0;
      border-bottom: 1px solid #E2E5EB;
      cursor: pointer;
      & > div {
        &:first-of-type {
          width: 88%;
          display: flex;
          align-items: center;
          .checkbox-wrapper {
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .product-image {
            max-width: 64px;
            max-height: 64px;
            margin-right: 30px;
          }
          .checkbox {
            width: 24px;
            height: 24px;
            cursor: pointer;
            margin-right: 30px;
            &.exists {
              opacity: 0.5;
              cursor: help;
            }
            &.not-valid {
              opacity: 0.25;
              cursor: not-allowed;
            }
          }
          .not-valid-image {
            height: 64px;
            width: 64px;
            min-width: 64px;
            border-radius: 4px;
            background-color: #FBE8E8;
            margin-right: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            .warning-icon {
              height: 24px;
              width: 24px;
              margin-left: 0;
            }
          }
          .product_info {
            width: 100%;
            .name {
              font-size: 20px;
              line-height: 24px;
              font-family: "InterSemiBold", sans-serif;
              max-width: 1000px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 8px;
              display: flex;
              align-items: center;
              &.not-valid {
                color: rgba(56, 56, 56, 0.5);
              }
            }
            .product-description {
              font-size: 14px;
              line-height: 18px;
              color: #9B9B9B;
              max-width: min(1000px, 44vw);
              display: flex;
              align-items: center;
              span {
                max-width: 90%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &.not-valid {
                color: rgba(155, 155, 155, 0.5);
              }
            }
          }
        }
        &:last-of-type {
          width: 12%;
          .price {
            .label {
              font-size: 14px;
              line-height: 18px;
              color: #9B9B9B;
              margin-bottom: 8px;
            }
            .value {
              font-size: 20px;
              line-height: 24px;
              font-family: "InterSemiBold", sans-serif;
              display: flex;
              align-items: center;
              &.not-valid {
                color: rgba(56, 56, 56, 0.5);
              }
            }
          }
        }
      }
    }
    .btn_wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .select_all {
      margin-top: 30px;
      border-bottom: 1px solid #E2E5EB;
      padding-bottom: 24px;
      & > div {
        width: max-content;
        cursor: pointer;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 30px;
      }
      font-size: 14px;
      line-height: 18px;
      color: #9B9B9B;
    }
  }
}
.invitation_wrapper{
  display: flex;
  justify-content: center;
  padding-top: 10vh;
  width: 100%;
  background: rgba(242, 242, 242, 0.6);
  min-height: calc(100vh - 10vh);
  >div{
    height: max-content;
    width: 485px;
    background-color: #FFFFFF;
    padding: 70px 120px 60px;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
    .invitation_text {
      max-width: 350px;
      margin: 80px 0;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #9B9B9B;
      span {
        color: #383838;
        font-family: "InterSemiBold", sans-serif;
      }
    }
    .invitation_expired {
      padding: 30px;
      background: #EEF3FF;
      border-radius: 8px;
      max-width: 460px;
      margin: 40px 0;
      font-size: 16px;
      line-height: 26px;
      color: #324EA3;
      text-align: left;
    }
  }
  .btn_wrapper {
    display: flex;
    .default_button_wrapper {
      display: flex;
      .default_button.auth{
        margin-left: 20px;
        height: 41px;
        width: 209px;
        min-width: 153px;
        font-size: 14px;
      }
    }
  }
}
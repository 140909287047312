.analytics_wrapper {
  display: flex;
  width: 100vw;
  .analytics_container {
    display: flex;
    flex-flow: column;
    width: calc(100% - 504px);
    padding: 50px 73px 200px;
    background-color: #F8F8F8;
    background-size: 20px 20px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
}

.analytics-header {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .select_component {
    width: 123px;
  }
  &__title {
    font-family: "InterSemiBold", sans-serif;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #383838;
  }
  &__controls {
    display: flex;
    align-items: center;
  }
  &__users {
    color: #9B9B9B;
    font-family: "InterSemiBold", sans-serif;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    &--icon {
      margin-right: 8px;
      width: 17px;
      height: 17px;
    }
    &--participants {
      color: #324EA3;
    }
  }
  &__separator {
    margin: 0 14px;
    height: 37px;
    width: 1px;
    background-color: #E9E9E9;
  }
  &__period {
    margin-right: 30px;
    color: #9B9B9B;
    font-family: "InterSemiBold", sans-serif;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    &--icon {
      margin-right: 8px;
      width: 17px;
      height: 17px;
    }
  }
}

.analytics {
  width: calc(100% - 40px);
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 5px 7px -3px rgba(88, 88, 88, 0.2), 0px 12px 19px 1px rgba(92, 92, 92, 0.06), 0px 5px 23px 4px rgba(89, 89, 89, 0.05);
  border-radius: 8px;
  .title {
    color: #656565;
    font-family: "InterSemiBold", sans-serif;
    text-transform: uppercase;
    font-size: 12px;
  }
  .reach_wrapper {
    display: flex;
    width: 100%;
    margin: 33px 0 23px;
    .reach_item {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      .name {
        color: #000000;
        font-family: "InterSemiBold", sans-serif;
        font-size: 14px;
        margin-bottom: 7px;
      }
      .value {
        color: #6429B3;
        font-family: "InterSemiBold", sans-serif;
        font-size: 20px;
      }
    }
  }
  .chart_wrapper {
    margin-top: 40px;
    width: 100%;
    & > div {
      overflow: visible!important;
    }
  }
}

.analytics_details {
  display: flex;
  margin: 30px 0;
  .analytics_block {
    display: flex;
    width: 100%;
    flex-flow: column;
    align-items: flex-start;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px -2px rgba(88,88,88,0.3), 0 3px 13px 2px rgba(89,89,89,0.05);
    margin-right: 30px;
    padding: 20px;
    position: relative;
    &:last-of-type {
      margin-right: 0;
    }
    .title {
      color: #656565;
      font-family: "InterSemiBold", sans-serif;
      text-transform: uppercase;
      font-size: 12px;
    }
    .number {
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
      font-size: 32px;
      letter-spacing: 0;
      line-height: 39px;
      margin: 13px 0 4px;
    }
    .percentage {
      color: #9B9B9B;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
      text-transform: lowercase;
    }
    .graph {
      margin: 22px 0 10px;
      height: 50px;
      display: flex;
      width: 100%;
      align-items: flex-end;
      .line {
        width: 100%;
        background-color: #F23EA6;
        margin-right: 4px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    .result_per_week {
      display: flex;
      align-items: center;
      color: #9B9B9B;
      font-family: "InterSemiBold", sans-serif;
      font-size: 10px;
      span {
        margin: 0 4px 0 8px;
        color: #EB0008;
        &.green {
          color: #069284;
        }
      }
    }
    .details_table {
      margin: 10px 0;
      display: flex;
      flex-flow: column;
      width: 100%;
      .details_line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E9E9E9;
        & > div {
          margin: 8px 0;
          &:first-of-type {
            color: #000000;
            font-family: "InterSemiBold", sans-serif;
            font-size: 12px;
          }
          &:last-of-type {
            color: #6429B3;
            font-family: "InterSemiBold", sans-serif;
            font-size: 12px;
          }
        }
      }
    }
    .add-audience {
      &__btn {
        position: absolute;
        top: 20px;
        right: 20px;
      }
      &__menu {
        z-index: 100;
        right: -5px;
        top: 37px;
        position: absolute;
        box-sizing: border-box;
        height: max-content;
        width: 186px;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 6px 0 rgba(43, 44, 51, 0.3);
        &--option {
          text-align: left;
          width: 100%;
          font-family: "InterSemiBold", sans-serif;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 18px;
          padding: 20px;
          color: #383838;
          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }
        &--separator {
          height: 1px;
          width: 100%;
          background: #CECECE;
        }
      }
    }
  }
}

.total_blocks_wrapper {
  display: flex;
  column-gap: 30px;
  .total_block {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border-radius: 8px;
    padding: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px -2px rgba(88,88,88,0.3), 0 3px 13px 2px rgba(89,89,89,0.05);
    .title {
      color: #656565;
      font-family: "InterSemiBold", sans-serif;
      text-transform: uppercase;
      font-size: 12px;
    }
    .total_big {
      margin: 24px 0;
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      .total_value {
        color: #6429B3;
        font-family: "InterSemiBold", sans-serif;
        font-size: 32px;
        letter-spacing: 0;
        line-height: 39px;
        margin-bottom: 4px;
      }
      .total_label {
        color: #9B9B9B;
        font-family: "InterSemiBold", sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
    .details_wrapper {
      width: 100%;
      display: flex;
      .details_block {
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        &:nth-of-type(2) {
          border-left: 1px solid #E9E9E9;
        }
        .details_value {
          color: #383838;
          font-family: "InterSemiBold", sans-serif;
          font-size: 24px;
          letter-spacing: 0;
          line-height: 29px;
          margin-bottom: 8px;
        }
        .details_label {
          color: #9B9B9B;
          font-family: "InterSemiBold", sans-serif;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 15px;
        }
      }
    }
  }
}
.default_button_wrapper {
  .default_button {
    text-align: center;
    display: inline-flex;
    font-family: "InterBold", sans-serif;
    font-size: 14px;
    white-space: nowrap;
    box-shadow: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    img{
      margin-left: 15px;
    }
    &:disabled {
      opacity: 0.5;
      box-shadow: none;
    }
    &.purple_btn {
      height: 40px;
      min-width: 153px;
      border: 1px solid #F23EA6;
      border-radius: 20.5px;
      background-color: #F23EA6;
      color: #FFFFFF;
    }
    &.auth {
      height: 40px;
      min-width: 200px;
      border-radius: 20.5px;
      background-color: #F23EA6;
      color: #ffffff;
    }
    &.sources_btn {
      height: 40px;
      min-width: 200px;
      border-radius: 20.5px;
      border: 1px solid #F23EA6;
      color: #F23EA6;
    }
    &.delete {
      height: 40px;
      min-width: 153px;
      border-radius: 20.5px;
      background-color: #F23EA6;
      color: #ffffff;
    }
    &.add_data_source {
      height: 40px;
      min-width: 284px;
      border-radius: 20.5px;
      background-color: #F23EA6;
      color: #ffffff;
    }
    &.auth_create {
      height: 40px;
      min-width: 235px;
      border-radius: 20.5px;
      background-color: #F23EA6;
      color: #ffffff;
    }
    &.auth_sign_in_link {
      height: 40px;
      min-width: 153px;
      border-radius: 20.5px;
      background-color: #F23EA6;
      color: #ffffff;
    }
    &.auth_resent_link {
      height: 40px;
      min-width: 230px;
      border-radius: 20.5px;
      background-color: #F23EA6;
      color: #ffffff;
    }
    &.auth_forgot_pass {
      height: 40px;
      min-width: 286px;
      border-radius: 20.5px;
      background-color: #F23EA6;
      color: #ffffff;
    }
    &.google {
      height: 46px;
      min-width: 123px;
      border-radius: 23px;
      background-color: #4285F4;
      color: #ffffff;
    }
    &.apple {
      height: 46px;
      min-width: 123px;
      border-radius: 23px;
      background-color: #000000;
      color: #ffffff;
    }
    &.facebook {
      height: 46px;
      min-width: 123px;
      border-radius: 23px;
      background-color: #3B5998;
      color: #ffffff;
    }
    &.generate_link {
      height: 41px;
      min-width: 185px;
      background: #F5F5F5;
      border-radius: 20px;
      color: #111111;
    }
    &.add_answer {
      min-width: 140px;
      background: #F5F5F5;
      border-radius: 20px;
      color: #111111;
      height: 30px;
      font-size: 11px;
      margin: 0!important;
    }
    &.preview_btn {
      height: 35px;
      min-width: 119px;
      background: #F5F5F5;
      border-radius: 17.5px;
      color: #111111;
      letter-spacing: 1px;
      font-size: 12px;
    }
    &.save_btn {
      height: 34px;
      min-width: 90px;
      background-color: #F23EA6;
      color: #ffffff;
      border-radius: 17.5px;
      letter-spacing: 1px;
      font-size: 12px;
    }
    &.reject {
      height: 41px;
      min-width: 116px;
      background: #FFFFFF;
      border-radius: 20px;
      color: #F23EA6;
    }
    &.cancel_btn {
      height: 41px;
      min-width: 153px;
      background: #F5F5F5;
      border-radius: 20px;
      color: #111111;
    }
    &.add-size {
      height: 29px;
      min-width: 108px;
      background: #F5F5F5;
      border-radius: 15px;
      color: #111111;
      font-size: 11px;
      font-family: "InterBold", sans-serif;
      letter-spacing: 1px;
      line-height: 12px;
    }
    &.edit_btn {
      height: 34px;
      min-width: 90px;
      background: #F5F5F5;
      border-radius: 17px;
      color: #111111;
      img {
        margin-left: 0;
      }
    }
    &.test_btn {
      height: 40px;
      min-width: 100px;
      background: #F5F5F5;
      border-radius: 20px;
      color: #111111;
    }
    &.ok_btn {
      height: 40px;
      min-width: 128px;
      background-color: #F23EA6;
      color: #ffffff;
      border: 1px solid #F23EA6;
      border-radius: 20.5px;
      text-transform: uppercase;
    }
    &.campaign_save_btn {
      height: 40px;
      width: 153px;
      border: 1px solid #F23EA6;
      border-radius: 20.5px;
      background-color: #F23EA6;
      color: #ffffff;
      text-transform: uppercase;
    }
    &.download_btn {
      padding: 0;
      span {
        &:first-of-type {
          width: 100%;
          line-height: 28px;
          height: 100%;
          a {
            color: inherit;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &.add_product {
      height: 40px;
      min-width: 256px;
      color: #ffffff;
      border: 1px solid #F23EA6;
      border-radius: 20.5px;
      background-color: #F23EA6;
      text-transform: uppercase;
    }
    &.yes_btn {
      height: 40px;
      min-width: 120px;
      background-color: #F23EA6;
      color: #ffffff;
      border: 1px solid #F23EA6;
      border-radius: 20.5px;
    }
    &.no_btn {
      height: 40px;
      width: 120px;
      border: 1px solid #F5F5F5;
      border-radius: 20px;
      background-color: #F5F5F5;
      color: #111111;
    }
    &.upload_btn {
      height: 27px!important;
      min-width: 75px;
      border: 1px solid #F23EA6;
      border-radius: 13.5px;
      text-transform: capitalize;
    }
    &.switch_btn {
      height: 27px!important;
      min-width: 86px;
      border: 1px solid #F23EA6;
      border-radius: 13.5px;
      text-transform: capitalize;
      color: #F23EA6;
      font-size: 10px;
      font-family: "InterMedium", sans-serif;
      margin: 10px 25px;
    }
    &.leave_btn {
      height: 27px!important;
      min-width: 86px;
      border: 1px solid #CECECE;
      border-radius: 13.5px;
      text-transform: capitalize;
      color: #656565;
      font-size: 10px;
      font-family: "InterMedium", sans-serif;
    }
    &.delete_avatar_btn {
      height: 27px!important;
      min-width: 75px;
      border: none;
      border-radius: 13.5px;
      text-transform: capitalize;
      margin-left: 24px;
      color: #656565!important;
    }
    &.disconnect_btn {
      height: 27px!important;
      min-width: 86px;
      border: 1px solid #CECECE;
      border-radius: 13.5px;
      text-transform: capitalize;
      color: #656565;
      font-family: "InterMedium", sans-serif;
      font-size: 10px;
    }
    &.social_outlined {
      height: 27px!important;
      min-width: 86px;
      border: 1px solid #F23EA6;
      border-radius: 13.5px;
      font-size: 10px;
      color: #F23EA6;
      font-family: "InterMedium", sans-serif;
      text-transform: capitalize;
    }
    &.stop_campaign {
      height: 41px;
      width: 196px;
      border: 1px solid #383838;
      border-radius: 20px;
      color: #383838;
      font-family: "InterBold", sans-serif;
      font-size: 14px;
    }
    &.delivery_btn {
      height: 27px!important;
      min-width: 96px;
      border: 1px solid #F23EA6;
      border-radius: 13.5px;
      font-size: 10px;
      color: #F23EA6;
      font-family: "InterMedium", sans-serif;
      text-transform: unset;
      background-color: #ffffff;
    }
    &.sort-btn {
      height: 37px;
      width: 37px;
      min-width: 37px;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      img {
        margin: 0;
        transition: 0.1s linear all;
      }
      &.rotated {
        img {
          transform: rotate(180deg);
        }
      }
    }
    &.cancel-btn {
      height: 40px;
      min-width: 153px;
      border-radius: 20px;
      background-color: #F5F5F5;
    }
    &.calculate_btn {
      border: 1px solid #324EA3;
      border-radius: 13.5px;
      height: 27px;
      width: 93px;
      color: #324EA3;
      font-family: "InterMedium", sans-serif;
      font-size: 10px;
      letter-spacing: 0;
      text-transform: unset;
      line-height: 15px;
    }
    &.step-results__show-btn {
      text-transform: none;
      height: 36px;
      min-width: 138px;
      border-radius: 20px;
      background-color: #FFFFFF;
      box-shadow: 0 4px 8px -2px rgba(0,0,0,0.15);
      border: none;
      display: flex;
      align-items: center;
      position: absolute;
      right: 32px;
      top: 20px;
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 16px;
      .show-icon {
        height: 12px;
        width: 12px;
        margin: 0 2px 0 0;
      }
    }
    &.plus-icon-btn {
      height: 32px;
      width: 32px;
      min-width: 32px;
      border: 1px solid #F23EA6;
      border-radius: 20px;
      background-color: #F23EA6;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-left: 0;
      }
    }
  }
}
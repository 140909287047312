.header_wrapper {
  height: 60px;
  background-color: #6429B3;
  padding: 0 40px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  display: flex;

  .logo-and-breadcrumbs {
    display: flex;
    align-items: center;
    column-gap: 40px;
  }

  .logo {
    margin-top: 10px;
    color: #ffffff;
    width: 107px;
    min-width: 107px;
  }

  .header_info {
    display: flex;
    align-items: center;
    .alarm_block{
      cursor: pointer;
      margin-right: 30px;
      transition: all 0.3s linear;
      img {
        min-width: 16px;
      }
      &:hover{
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
      }
    }
    .info{
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      >div{
        display: flex;
        flex-flow: column;
        >img{
          margin-left: 22px;
          cursor: pointer;
        }
        & > span{
          color: #FFFFFF;
          font-family: "InterSemiBold",sans-serif;
          margin-bottom: 2px;
          font-size: 14px;
          line-height: 17px;
        }
        & > p{
          opacity: 0.7;
          color: #FFFFFF;
          font-family: "InterSemiBold",sans-serif;
          font-size: 11px;
          line-height: 14px;
        }

      }
      .menu {
        z-index: 1000;
        right: -10px;
        top: 36px;
        position: absolute;
        box-sizing: border-box;
        height: max-content;
        width: 194px;
        border: 1px solid #CECECE;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 10px 30px 0 rgba(0,0,0,0.2);
        color: #000000;
        & > a, & > button {
          padding: 20px 25px;
          text-align: left;
          font-size: 14px;
          font-family: "InterRegular", sans-serif;
          display: flex;
          align-items: center;
          color: #000000;
          &:hover {
            background-color: rgba(0,0,0,0.05);
          }
          img {
            min-width: 12px;
            margin-right: 12px;
          }
        }
        .companies_list {
          margin: 10px 0;
          cursor: default;
          p {
            padding: 10px 25px 7px;
            font-family: "InterSemiBold", sans-serif;
            font-size: 11px;
            line-height: 13px;
            text-transform: uppercase;
            color: #9B9B9B;
          }
          & > div:not(.default_button_wrapper) {
            display: flex;
            padding: 10px 25px;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            img {
              opacity: 0;
              min-width: 12px;
              margin-right: 12px;
              &.current {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .header_separator {
      height: 20px;
      width: 1px;
      background-color: #FFFFFF;
      opacity: 0.3;
      margin: 0 30px 0 18px;
    }
    .balance_info {
      font-family: "InterSemiBold", sans-serif;
      color: #FFFFFF;
      text-align: end;
      margin-right: 2px;
      .balance {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 1px;
      }
      .company_balance {
        opacity: 0.7;
        font-size: 11px;
        line-height: 14px;
      }
    }
    .add_btn {
      border-radius: 50%;
      border: 2px solid #ffffff;
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-size: 21px;
    }
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}



.product_rewards{
  padding: 0 30px;
  .title{
    display: flex;
    align-content: center;
    padding-bottom: 7px;
    span{
      color: #383838;
      font-family: "InterSemiBold",sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin-right: 5px;
    }
    img {
      cursor: help;
    }
  }
  .select_container {
    width: calc(100% - 159px);
  }
  .select_wrapper{
    width: 100%;
  }
}

.info_rewards{
  margin-right: 30px;
  display: flex;
  flex-flow: column;
  p{
    color: #383838;
    font-family: "InterSemiBold",sans-serif;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 7px;
  }
  span{
    color: #383838;
    font-family: "InterRegular",sans-serif;
    font-size: 16px;
    line-height: 20px;
    height: 38px;
    display: flex;
    align-items: center;
  }

}

.discount_price{
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .price_block_big{
    padding: 0;
    margin-right: 30px;
    img {
      cursor: help;
    }
  }
}

.discount_type{
  padding: 0 30px;
  .title{
    display: flex;
    align-content: center;
    padding-bottom: 7px;
    span {
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin-right: 5px;
    }
    img {
      cursor: help;
    }
  }

  .item_radio{
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
    &:first-child{
      margin-bottom: 10px;
    }
    & > div{
      display: flex;
      align-items: center;
      margin-right: 40px;
      & > input{
        display: none;
        & + label{
          position: relative;
          cursor: pointer;
          padding-left: 30px;
          &:before, &:after{
            content: "";
            position: absolute;
            border-radius: 50%;;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }
          &:before{
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background: url("../../../../../assets/image/RadioOff.png") no-repeat;
          }
        }
        &:checked + label:before{
          background: url("../../../../../assets/image/RadioOn.png") no-repeat;

        }
      }
    }
    label{
      color: #383838;
      font-family: "InterRegular",sans-serif;
      font-size: 12px;
      line-height: 20px;
    }
  }

}

.bonus_rewards {
  display: flex;
  justify-content: space-between;
}
.custom-audience {
  &__page {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5% 0;
    height: max-content;
    min-height: calc(100vh - 60px);
    background: rgba(242, 242, 242, 0.6);
    box-sizing: border-box;
  }
  &__wrapper {
    height: max-content;
    box-sizing: border-box;
    background-color: #FFFFFF;
    padding: 75px 80px 72px;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
    &.segment_name {
      width: 760px;
    }
    &.segment_groups {
      width: 835px;
    }
  }
  &__title {
    color: #383838;
    font-family: "InterSemiBold",sans-serif;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 48px;
  }
  &__cancel {
    margin-top: 60px;
    color: #F23EA6;
    font-family: "InterBold",sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
  }
  &__segment-name {
    width: 410px;
    &.edit-mode {
      width: 550px;
    }
    &--label {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 16px;
      text-align: center;
      &.edit-mode {
        font-family: "InterSemiBold",sans-serif;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 13px;
        width: 100%;
        text-align: left;
      }
    }
    &--input-wrapper {
      width: 100%;
      input {
        height: 48px;
        width: 100%;
      }
      &.edit-mode {
        input {
          width: 100%;
          padding: 11px 15px;
          height: 37px;
        }
      }
    }
  }
  &__segment-groups {
    width: 100%;
    display: flex;
    flex-flow: column;
    &--description {
      color: #656565;
      font-size: 16px;
      letter-spacing: -0.18px;
      line-height: 20px;
      margin: -24px auto 48px;
    }
  }
  &__segment-group {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    margin-bottom: 30px;
    &--title {
      width: 160px;
      border-right: 2px solid #E9E9E9;
      padding-top: 5px;
      &-text {
        color: #000000;
        font-family: "InterSemiBold", sans-serif;
        font-size: 16px;
        line-height: 20px;
        max-width: 110px;
      }
    }
    &--content-box {
      display: flex;
      flex-flow: column;
      padding: 6px 0 10px 40px;
      width: 455px;
      box-sizing: border-box;
      .default_button_wrapper {
        margin-top: 40px;
      }
      .small-input {
        height: 35px;
        width: 60px;
        margin-right: 15px;
      }
      .radius-input {
        height: 35px;
        width: 107px;
        margin-right: 15px;
      }
      .suggest-wrapper {
        width: 208px;
        .address-input {
          margin-right: 0!important;
          input {
            height: 35px;
            width: 100%;
          }
        }
      }
    }
    &--add {
      margin-bottom: 60px;
      color: #F23EA6;
      font-family: "InterSemiBold", sans-serif;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;
    }
    &--inline-text {
      margin-right: 15px;
      color: #383838;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      &.bold-text {
        font-family: "InterSemiBold", sans-serif;
      }
    }
    &--field {
      width: 100%;
      height: 35px;
      .select__control {
        min-height: 35px!important;
      }
      &.small-selector {
        width: 120px;
      }
    }
    &--fields-box {
      width: 455px;
      display: flex;
      align-items: center;
      &.flex-col {
        flex-flow: column;
        align-items: flex-start;
      }
      .item_checkbox {
        flex-flow: row!important;
        & > div {
          margin-bottom: 0!important;
          margin-right: 15px;
        }
      }
      .interests_wrapper {
        width: 100% !important;
      }
      .map_wrapper{
        box-sizing: border-box;
        height: 226px;
        width: 100%;
        overflow: hidden;
        >div{
          position: relative;
          height: 100%;
          width: 100%;
          background: #CECECE;
        }
      }
    }
  }
  &__suitable-users {
    box-sizing: border-box;
    height: 80px;
    width: 100%;
    border-radius: 8px;
    background-color: #EEF3FF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
    &--number {
      margin-right: 10px;
      color: #324EA3;
      font-family: "InterSemiBold", sans-serif;
      font-size: 20px;
      line-height: 24px;
    }
    &--description {
      font-size: 12px;
      line-height: 15px;
    }
  }
  &__submit-btn {
    margin-top: 60px;
    width: 100%;
    text-align: center;
  }
}
.message_popper {
  .message_tooltip {
    background: #030303;
    border-radius: 4px;
    font-size: 12px;
    word-break: break-word;
    font-family: "InterRegular", sans-serif;
    padding: 12px;
    line-height: 15px;
  }
  .error_message_tooltip {
    background-color: #E02020;
    font-size: 12px;
    line-height: 13px;
    padding: 12px;
    font-family: "InterRegular", sans-serif;
  }
}

body {
  .auth_tooltip_popper {
    top: 8px !important;
    left: 9px;
  }
}

.step_tooltip {
  z-index: 10000000;
}
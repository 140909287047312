.file-input {
  display: none;
}

.drop-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 119px;
  width: 100%;
  border: 2px dashed #CECECE;
  border-radius: 4px;
  background-color: #FFFFFF;
  cursor: pointer;
  span{
    color: #9B9B9B;
    font-size: 12px;
    line-height: 15px;
  }
}

.upload-icon {
  width: 50px;
  height: 50px;
  background: url(../../../assets/image/My_Campaign/upload.svg) no-repeat center center;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.drop-message {
  color: #9B9B9B;
  font-size: 12px;
  line-height: 15px;
}
.panel_wrapper{
  min-height: calc(100vh - 60px);
  width: 320px;
  min-width: 320px;
  background-color: #FFFFFF;
  box-shadow: 1px 0 6px 0 rgba(0,0,0,0.1);
  padding: 40px 0 60px 65px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  >a{
    opacity: 0.7;
    color: #656565;
    font-family: "InterRegular",sans-serif;
    font-size: 18px;
    line-height: 21px;
    height: 64px;
    display:flex;
    align-items: center;
    border-right: 4px solid transparent;
    padding-left: 40px;
    position: relative;
    .hover{
      z-index: -1;
      transition: all ease .3s;
      opacity: 0;
    }
    img{
        position: absolute;
        left: 0;
      }
    &:hover{
      opacity: 1;
      color: #F23EA6;
      .hover{
        opacity: 1;
        z-index: 9;
      }
    }
  }
  .active{
    opacity: 1;
    color: #F23EA6;
    border-color: #F23EA6 ;
    font-family: "InterSemiBold", sans-serif;
    .hover{
      opacity: 1;
      z-index: 99;
    }
  }
  .hr{
    background: #E9E9E9;
    width: 320px;
    height: 1px;
    display: flex;
    margin: 30px 0;
    margin-left: -65px;
  }
}
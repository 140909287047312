.auth_container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth_bg {
  background: url("../../assets/image/auth_bg_with_assets.png") no-repeat 50% 50%;
  -webkit-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .auth_bg_text_wrapper {
    max-width: 400px;
    & > div {
      &:first-of-type {
        font-size: 76px;
        font-family: "InterExtraBold", sans-serif;
        letter-spacing: -2.53px;
        line-height: 76px;
        color: #4EEAD6;
      }
      &:nth-of-type(2) {
        color: #FFFFFF;
        font-family: "InterRegular", sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 26px;
        margin: 27px 0 70px;
        span {
          font-family: "InterBold", sans-serif;
        }
      }
      &:last-of-type {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        font-family: "InterRegular", sans-serif;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 23.4px;
        span {
          font-family: "InterBold", sans-serif;
        }
        img {
          margin-right: 25px;
        }
      }
    }
  }
}

.auth_content {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.auth-box {
  width: 100%;
  min-height: 100vh;
  display: flex;
  & > :first-child {
    width: 40%;
  }
  & > :last-child {
    width: 60%;
  }
}

.resend_link {
  display: flex;
  align-items: center;
  color: #F23EA6;
  font-family: "InterBold", sans-serif;
  font-size: 14px;
  justify-content: center;
  margin-top: 32px;
  margin-right: 20px;
}

.auth_redirect {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-family: "InterMedium", sans-serif;
  font-size: 14px;
  margin: 80px 0 135px 90px;
  &.with-lng-switcher {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .language-switcher {
      margin-left: -90px;
    }
  }
  a {
    font-family: "InterBold", sans-serif;
    margin-left: 4px;
    color: var(--textColorPurple);
  }
}

.sign_in_wrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 100%;
  height: auto;
  width: 500px;
  form {
    width: 400px;
    position: relative;
    &.login_form {
      min-height: 256px;
    }
  }
  .auth_block_head{
    font-size: 30px;
    font-family: "InterBold", sans-serif;
  }
  .auth_description {
    margin: 30px 0 84px;
    color: #383838;
    font-size: 16px;
    line-height: 26px;
    position: relative;
    .back_link {
      top: -140px;
      color: #F23EA6;
      font-family: "InterBold", sans-serif;
      font-size: 14px;
      letter-spacing: 1px;
      position: absolute;
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
  }
  .or_separator {
    margin-bottom: 62px;
    position: relative;
    span {
      font-family: "InterBold", sans-serif;
      text-transform: uppercase;
      position: absolute;
      font-size: 11px;
      z-index: 1;
      margin: auto;
      top: -7px;
      left: calc(50% - 14px);
      padding: 0 7px;
      background-color: white;
    }
  }
  .forgot_password {
    position: absolute;
    right: 0;
    font-family: "InterMedium", sans-serif;
    font-size: 12px;
    color: #111111;
    opacity: 0.4;
    cursor: pointer;
    font-weight: 500;
    top: calc(50% - 52px);
    text-decoration: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    &:hover {
      text-decoration: underline;
    }
  }
}

.auth_buttons_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 40px 0 38px;
}

.auth_btn_wrapper {
  display: flex;
  align-items: center;
  &.sign_in_link_wrapper {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    .default_button_wrapper {
      width: 100%;
    }
  }
  .default_button_wrapper{
    margin-right: 20px;
  }
  .MuiSvgIcon-root{
    cursor: pointer;
    >path:last-of-type{
      fill: red
    }
  }
}

.terms_policy_wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 8vh 0;
  a {
    text-decoration: underline;
    color: var(--textColorPrimaryWithOpacity);
    font-size: 12px;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    &:last-of-type {
      margin-left: 40px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
.customWrapper{
  padding: 12px 32px;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  cursor: pointer;
  background-color: #FFFFFF;
}
.input{
  & + div{
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 8px 12px 0 #000000;
  }
}
.deliveries {
  &__wrapper {
    display: flex;
    flex-flow: column;
    padding: 50px 68px 82px 64px;
    background-color: #F8F8F8;
    width: 100%;
  }
  &__title {
    margin-bottom: 32px;
    color: #383838;
    font-size: 24px;
    line-height: 26px;
    font-family: "InterSemiBold", sans-serif;
  }
  &__meta-controls {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
  }
  &__search {
    position: relative;
    margin-right: 20px;
    input {
      box-sizing: border-box;
      height: 37px;
      width: 288px;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding: 0 20px 0 39px;
      font-family:"InterRegular",sans-serif;
      color: #111111;
      transition: all 0.3s linear;
      &::placeholder {
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus {
        border-color:#111111 ;
      }
    }
    img {
      position: absolute;
      width: 16px;
      height: 16px;
      left: 15px;
      top: calc(50% - 8px);
    }
  }
  &__select {
    width: 194px;
    margin-right: 20px!important;
    &.longer-one {
      width: 232px;
    }
    &.max-width {
      width: 100%
    }
  }
  &__list {
    display: flex;
    flex-flow: column;
  }
  &__item {
    padding: 32px 40px;
    margin-bottom: 30px;
    border-radius: 8px;
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(250px, 1fr) 120px 1fr 1fr 1fr;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
    &--username {
      margin-bottom: 8px;
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
      font-size: 20px;
      line-height: 22px;
    }
    &--email {
      color: #9B9B9B;
      font-size: 14px;
      line-height: 17px;
    }
    &--label {
      margin-bottom: 4px;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
    }
    &--value {
      color: #383838;
      font-family: "InterMedium", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
    &--status {
      color: #6429B3;
      font-family: "InterSemiBold", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
    &--updated-at {
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
      span {
        color: #656565;
      }
    }
    &--controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &--action-btn {
      text-align: end;
    }
  }
  &__info-dialog {
    padding: 40px;
    &--wrapper {
      width: 460px;
      align-items: flex-start;
    }
    &--title {
      margin-bottom: 32px;
      color: #383838;
      font-family: "InterSemiBold", sans-serif;
      font-size: 24px;
      line-height: 26px;
    }
    &--info {
      padding: 24px;
      border-radius: 8px;
      background-color: #F8F8F8;
      display: flex;
      flex-flow: column;
      row-gap: 24px;
      width: 100%;
      box-sizing: border-box;
    }
    &--label, h6 {
      margin-bottom: 4px;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 16px;
    }
    &--value, p {
      color: #383838;
      font-family: "InterMedium", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
    &--fields {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    .in_line_info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 24px;
    }
  }
}
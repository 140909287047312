.add_campaign_wrapper{
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  background: rgba(242, 242, 242, 0.6);
  .back{
    color: #F23EA6;
    font-family: "InterBold",sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
  }
  >div{
    height: max-content;
    background-color: #FFFFFF;
    padding: 70px 165px 40px;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 5px 7px -3px rgba(88,88,88,0.2), 0 12px 19px 1px rgba(92,92,92,0.06), 0 5px 23px 4px rgba(89,89,89,0.05);
  }
  .title{
    color: #383838;
    font-family: "InterSemiBold",sans-serif;
    font-size: 32px;
    line-height: 39px;
    padding-bottom: 40px;
  }
  .info{
    color: #383838;
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 18px;
  }
  .input_wrapper{
    width: 410px;
    input{
      box-sizing: border-box;
      height: 37px;
      width: 100%;
      border: 1px solid #CECECE;
      border-radius: 4px;
      background-color: #FFFFFF;
      padding: 0 15px;
      font-family:"InterRegular",sans-serif;
      color: #111111;
      transition: all 0.3s linear;
      &::placeholder{
        color: #9B9B9B;
        font-size: 12px;
      }
      &:focus{
        border-color:#111111 ;
      }
    }
    p{
      padding-top: 6px;
      padding-bottom: 40px;
      color: #9B9B9B;
      font-size: 12px;
      line-height: 15px;
    }
  }
  .select_wrapper{
    width: 410px;
    margin-bottom: 50px;
  }
  .default_button_wrapper {
    margin-bottom: 73px;
    .default_button.auth{
      height: 40px;
      width: 153px;
      min-width: 153px;
      font-size: 14px;
    }
  }

}